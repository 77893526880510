import { useWebinarStore } from "~~/store/webinar";
import { useTimelineStore } from "~~/store/timeline";
import { useCommonStore } from "~~/store/common";
import { useIntegrationStore } from "~~/store/integration";
import { useMediaStore } from "~~/store/media";
import { useAuthStore } from "~~/store/auth";

export const getData = async () => {
  const webinarStore = useWebinarStore();
  const timelineStore = useTimelineStore();
  const commonStore = useCommonStore();
  const integrationStore = useIntegrationStore();
  const mediaStore = useMediaStore();
  const authStore = useAuthStore();
  try {
    await Promise.allSettled([
      webinarStore.fetchUpcomingWebinars(0, true),
      timelineStore.fetchTimelinesMaster(),
      integrationStore.fetchIntegrations(),
      webinarStore.fetchPastWebinars(),
      mediaStore.fetchImages(),
    ]);
    commonStore.setAllLoaded(true);
    timelineStore.getTemplateForms();
    webinarStore.fetchRecurring();
    mediaStore.fetchAudios();
    mediaStore.fetchVideos();
    mediaStore.getFolders()
    mediaStore.fetchSubtitles();
  } catch (err) {
    console.error("Error is:", err);
  }

  integrationStore.fetchNiftyImages();
};
