import { ref } from "vue";

export const pages = () => {
  return [
    {
      id: 1,
      name: "Dashboard",
      label: "index",
      icon: "homepage_icon.svg",
      route: "/",
    },
    {
      id: 2,
      name: "Campaigns",
      label: "Campaigns",
      icon: "timelines_icon.svg",
      route: "/campaigns",
    },
    {
      id: 3,
      name: "Media",
      label: "media",
      icon: "media_icon.svg",
      route: "/media-new",
    },
    {
      id: 4,
      name: "Integrations",
      label: "integrations",
      icon: "integrations_icon.svg",
      route: "/integrations",
    },
    {
      id: 5,
      name: "Leads",
      label: "leads",
      icon: "leads_icon.svg",
      route: "/leads",
    },
  ];
};
export const settingDropDown = () => {
  return [
    {
      id: 1,
      type: "heading",
      name: "Campaign Settings",
    },
    
    {
      id: 2,
      name: "event info",
      icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.66667 5V2.5M13.3333 5V2.5M4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V5.83333C17.5 4.91286 16.7538 4.16667 15.8333 4.16667H4.16667C3.24619 4.16667 2.5 4.91286 2.5 5.83333V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5Z" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.6667 13.6667H9.83333V10.3333H9M9.83333 7H9.84167" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      `,
      label: "eventInfo",
      type: "pageName",
    },
    {
      id: 3,
      name: "integrations",
      icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.1665 3.33341C9.1665 2.41294 9.9127 1.66675 10.8332 1.66675C11.7536 1.66675 12.4998 2.41294 12.4998 3.33341V4.16675C12.4998 4.62699 12.8729 5.00008 13.3332 5.00008H15.8332C16.2934 5.00008 16.6665 5.37318 16.6665 5.83341V8.33341C16.6665 8.79365 16.2934 9.16675 15.8332 9.16675H14.9998C14.0794 9.16675 13.3332 9.91294 13.3332 10.8334C13.3332 11.7539 14.0794 12.5001 14.9998 12.5001H15.8332C16.2934 12.5001 16.6665 12.8732 16.6665 13.3334V15.8334C16.6665 16.2937 16.2934 16.6667 15.8332 16.6667H13.3332C12.8729 16.6667 12.4998 16.2937 12.4998 15.8334V15.0001C12.4998 14.0796 11.7536 13.3334 10.8332 13.3334C9.9127 13.3334 9.1665 14.0796 9.1665 15.0001V15.8334C9.1665 16.2937 8.79341 16.6667 8.33317 16.6667H5.83317C5.37293 16.6667 4.99984 16.2937 4.99984 15.8334V13.3334C4.99984 12.8732 4.62674 12.5001 4.1665 12.5001H3.33317C2.4127 12.5001 1.6665 11.7539 1.6665 10.8334C1.6665 9.91294 2.4127 9.16675 3.33317 9.16675H4.1665C4.62674 9.16675 4.99984 8.79365 4.99984 8.33341V5.83342C4.99984 5.37318 5.37293 5.00008 5.83317 5.00008H8.33317C8.79341 5.00008 9.1665 4.62699 9.1665 4.16675V3.33341Z" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      `,
      label: "integrations",
      type: "pageName",
    },
    {
      id: 4,
      name: "registration",
      icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 5.83333C13.4205 5.83333 14.1667 6.57953 14.1667 7.5M17.5 7.5C17.5 10.2614 15.2614 12.5 12.5 12.5C11.9949 12.5 11.5072 12.4251 11.0476 12.2858L9.16667 14.1667H7.5V15.8333H5.83333V17.5H3.33333C2.8731 17.5 2.5 17.1269 2.5 16.6667V14.5118C2.5 14.2908 2.5878 14.0789 2.74408 13.9226L7.71423 8.95244C7.57491 8.49279 7.5 8.00514 7.5 7.5C7.5 4.73858 9.73858 2.5 12.5 2.5C15.2614 2.5 17.5 4.73858 17.5 7.5Z" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      `,
      label: "registration",
      type: "pageName",
    },
    {
      id: 5,
      name: "timeline",
      icon: `<svg width="20" height="20"  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 48 48">
      <path d="M 12.5 6 C 8.9280619 6 6 8.9280619 6 12.5 L 6 35.5 C 6 39.071938 8.9280619 42 12.5 42 L 35.5 42 C 39.071938 42 42 39.071938 42 35.5 L 42 12.5 C 42 8.9280619 39.071938 6 35.5 6 L 12.5 6 z M 12.5 9 L 35.5 9 C 37.450062 9 39 10.549938 39 12.5 L 39 14 L 9 14 L 9 12.5 C 9 10.549938 10.549938 9 12.5 9 z M 9 17 L 39 17 L 39 35.5 C 39 37.450062 37.450062 39 35.5 39 L 12.5 39 C 10.549938 39 9 37.450062 9 35.5 L 9 17 z M 15.5 21 A 2.5 2.5 0 0 0 15.5 26 A 2.5 2.5 0 0 0 15.5 21 z M 24 21 A 2.5 2.5 0 0 0 24 26 A 2.5 2.5 0 0 0 24 21 z M 32.5 21 A 2.5 2.5 0 0 0 32.5 26 A 2.5 2.5 0 0 0 32.5 21 z M 15.5 30 A 2.5 2.5 0 0 0 15.5 35 A 2.5 2.5 0 0 0 15.5 30 z M 24 30 A 2.5 2.5 0 0 0 24 35 A 2.5 2.5 0 0 0 24 30 z" fill="currentColor" />
      </svg>
      `,
      label: "calendar",
      type: "pageName",
    },
    {
      id: 6,
      name: "audience",
      icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.1665 16.6666H18.3332V14.9999C18.3332 13.6192 17.2139 12.4999 15.8332 12.4999C15.0368 12.4999 14.3275 12.8723 13.8696 13.4523M14.1665 16.6666H5.83317M14.1665 16.6666V14.9999C14.1665 14.4531 14.0611 13.9308 13.8696 13.4523M5.83317 16.6666H1.6665V14.9999C1.6665 13.6192 2.78579 12.4999 4.1665 12.4999C4.96284 12.4999 5.67222 12.8723 6.13004 13.4523M5.83317 16.6666V14.9999C5.83317 14.4531 5.93852 13.9308 6.13004 13.4523M6.13004 13.4523C6.74442 11.9174 8.24552 10.8333 9.99984 10.8333C11.7542 10.8333 13.2553 11.9174 13.8696 13.4523M12.4998 5.83325C12.4998 7.21396 11.3805 8.33325 9.99984 8.33325C8.61913 8.33325 7.49984 7.21396 7.49984 5.83325C7.49984 4.45254 8.61913 3.33325 9.99984 3.33325C11.3805 3.33325 12.4998 4.45254 12.4998 5.83325ZM17.4998 8.33325C17.4998 9.25373 16.7536 9.99992 15.8332 9.99992C14.9127 9.99992 14.1665 9.25373 14.1665 8.33325C14.1665 7.41278 14.9127 6.66659 15.8332 6.66659C16.7536 6.66659 17.4998 7.41278 17.4998 8.33325ZM5.83317 8.33325C5.83317 9.25373 5.08698 9.99992 4.1665 9.99992C3.24603 9.99992 2.49984 9.25373 2.49984 8.33325C2.49984 7.41278 3.24603 6.66659 4.1665 6.66659C5.08698 6.66659 5.83317 7.41278 5.83317 8.33325Z" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      `,
      label: "audience",
      type: "pageName",
    },
    {
      id: 7,
      name: "advanced",
      icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 2.5V4.16667M12.5 2.5V4.16667M7.5 15.8333V17.5M12.5 15.8333V17.5M4.16667 7.5H2.5M4.16667 12.5H2.5M17.5 7.5H15.8333M17.5 12.5H15.8333M5.83333 15.8333H14.1667C15.0871 15.8333 15.8333 15.0871 15.8333 14.1667V5.83333C15.8333 4.91286 15.0871 4.16667 14.1667 4.16667H5.83333C4.91286 4.16667 4.16667 4.91286 4.16667 5.83333V14.1667C4.16667 15.0871 4.91286 15.8333 5.83333 15.8333ZM7.5 7.5H12.5V12.5H7.5V7.5Z" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      `,
      label: "advanced",
      type: "pageName",
    },
    
    {
      id: 8,
      name: "templates",
      icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.3335 4.16659C3.3335 3.70635 3.70659 3.33325 4.16683 3.33325H15.8335C16.2937 3.33325 16.6668 3.70635 16.6668 4.16659V5.83325C16.6668 6.29349 16.2937 6.66659 15.8335 6.66659H4.16683C3.70659 6.66659 3.3335 6.29349 3.3335 5.83325V4.16659Z" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.3335 10.8333C3.3335 10.373 3.70659 9.99992 4.16683 9.99992H9.16683C9.62707 9.99992 10.0002 10.373 10.0002 10.8333V15.8333C10.0002 16.2935 9.62707 16.6666 9.16683 16.6666H4.16683C3.70659 16.6666 3.3335 16.2935 3.3335 15.8333V10.8333Z" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.3335 10.8333C13.3335 10.373 13.7066 9.99992 14.1668 9.99992H15.8335C16.2937 9.99992 16.6668 10.373 16.6668 10.8333V15.8333C16.6668 16.2935 16.2937 16.6666 15.8335 16.6666H14.1668C13.7066 16.6666 13.3335 16.2935 13.3335 15.8333V10.8333Z" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      `,
      label: "templates",
      type: "pageName",
    },
    {
      id: 9,
      name: "code",
      icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.33317 16.6666L11.6665 3.33325M14.9998 6.66659L18.3332 9.99992L14.9998 13.3333M4.99984 13.3333L1.6665 9.99992L4.99984 6.66659" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      `,
      label: "code",
      type: "pageName",
    },
    {
      id: 11,
      type: "devider",
    },
    {
      id: 10,
      type: "heading",
      name: "Version History",
    },
    {
      id: 12,
      name: "revisions",
      icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.16667 6.66659H15.8333M4.16667 6.66659C3.24619 6.66659 2.5 5.92039 2.5 4.99992C2.5 4.07944 3.24619 3.33325 4.16667 3.33325H15.8333C16.7538 3.33325 17.5 4.07944 17.5 4.99992C17.5 5.92039 16.7538 6.66659 15.8333 6.66659M4.16667 6.66659L4.16667 14.9999C4.16667 15.9204 4.91286 16.6666 5.83333 16.6666H14.1667C15.0871 16.6666 15.8333 15.9204 15.8333 14.9999V6.66659M8.33333 9.99992H11.6667" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      `,
      label: "revisions",
      type: "pageName",
    },
    {
      id: 14,
      type: "devider",
      name: "Scheduled Events",
    },
    {
      id: 13,
      type: "heading",
      name: "Scheduled Events",
    },
    {
      id: 15,
      name: "upcoming",
      icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.66667 5.83333V2.5M13.3333 5.83333V2.5M11.25 9.16667L13.3333 11.25L11.25 13.3333M7.08333 9.16667L9.16667 11.25L7.08333 13.3333M4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V5.83333C17.5 4.91286 16.7538 4.16667 15.8333 4.16667H4.16667C3.24619 4.16667 2.5 4.91286 2.5 5.83333V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5Z" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      `,
      label: "upcoming",
      type: "pageName",
    },
    {
      id: 16,
      name: "past",
      icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_5094_18732" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="2" y="2" width="16" height="16">
      <path d="M16.875 16.875V3.12504H3.125V16.875H16.875Z" fill="white" stroke="white" stroke-width="1.25"/>
      </mask>
      <g mask="url(#mask0_5094_18732)">
      <path d="M3.70261 3.08598V5.39067M3.70261 5.39067H6.0073M3.70261 5.39067C4.4996 4.01293 5.98919 3.08598 7.69532 3.08598H12.3047C14.8504 3.08598 16.914 5.14964 16.914 7.69533V12.3047C16.914 14.8504 14.8504 16.9141 12.3047 16.9141H7.69532C5.14963 16.9141 3.08594 14.8504 3.08594 12.3047V10M12.3047 12.3047L9.99998 10V6.543" stroke="currentColor" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      </svg>
      `,
      label: "past",
      type: "pageName",
    },
  ];
};

export const timezones = () => {
  var obj = {
    "Pacific/Tongatapu": "(GMT+13:00) Nukualofa",
    "Pacific/Fiji": "(GMT+12:00) Fiji, Kamchatka, Marshall Is.",
    "Pacific/Auckland": "(GMT+12:00) Auckland, Wellington",
    "Asia/Magadan": "(GMT+11:00) Magadan, Solomon Is., New Caledonia",
    "Asia/Vladivostok": "(GMT+10:00) Vladivostok",
    "Australia/Hobart": "(GMT+10:00) Hobart",
    "Pacific/Guam": "(GMT+10:00) Guam, Port Moresby",
    "Australia/Sydney": "(GMT+10:00) Canberra, Melbourne, Sydney",
    "Australia/Brisbane": "(GMT+10:00) Brisbane",
    "Australia/Darwin": "(GMT+09:30) Darwin",
    "Australia/Adelaide": "(GMT+09:30) Adelaide",
    "Asia/Yakutsk": "(GMT+09:00) Yakutsk",
    "Asia/Seoul": "(GMT+09:00) Seoul",
    "Asia/Tokyo": "(GMT+09:00) Osaka, Sapporo, Tokyo",
    "Asia/Taipei": "(GMT+08:00) Taipei",
    "Australia/Perth": "(GMT+08:00) Perth",
    "Asia/Singapore": "(GMT+08:00) Kuala Lumpur, Singapore",
    "Asia/Irkutsk": "(GMT+08:00) Irkutsk, Ulaan Bataar",
    "Asia/Shanghai": "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    "Asia/Krasnoyarsk": "(GMT+07:00) Krasnoyarsk",
    "Asia/Bangkok": "(GMT+07:00) Bangkok",
    "Asia/Jakarta": "(GMT+07:00) Hanoi, Jakarta",
    "Asia/Rangoon": "(GMT+06:30) Rangoon",
    "Asia/Colombo": "(GMT+06:00) Sri Jayawardenepura",
    "Asia/Dhaka": "(GMT+06:00) Astana, Dhaka",
    "Asia/Novosibirsk": "(GMT+06:00) Almaty, Novosibirsk",
    "Asia/Katmandu": "(GMT+05:45) Kathmandu",
    "Asia/Calcutta": "(GMT+05:30) Calcutta, Chennai, Mumbai, New Delhi",
    "Asia/Karachi": "(GMT+05:00) Islamabad, Karachi, Tashkent",
    "Asia/Yekaterinburg": "(GMT+05:00) Ekaterinburg",
    "Asia/Kabul": "(GMT+04:30) Kabul",
    "Asia/Tbilisi": "(GMT+04:00) Baku, Tbilisi, Yerevan",
    "Asia/Muscat": "(GMT+04:00) Abu Dhabi, Muscat",
    "Asia/Tehran": "(GMT+03:30) Tehran",
    "Africa/Nairobi": "(GMT+03:00) Nairobi",
    "Europe/Moscow": "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
    "Asia/Kuwait": "(GMT+03:00) Kuwait, Riyadh",
    "Asia/Baghdad": "(GMT+03:00) Baghdad",
    "Asia/Jerusalem": "(GMT+02:00) Jerusalem",
    "Europe/Helsinki": "(GMT+02:00) Helsinki, Riga, Tallinn",
    "Africa/Harare": "(GMT+02:00) Harare, Pretoria",
    "Africa/Cairo": "(GMT+02:00) Cairo",
    "Europe/Bucharest": "(GMT+02:00) Bucharest",
    "Europe/Athens": "(GMT+02:00) Athens, Istanbul, Minsk",
    "Africa/Malabo": "(GMT+01:00) West Central Africa",
    "Europe/Warsaw":
      "(GMT+01:00) Sarajevo, Skopje, Sofija, Vilnius, Warsaw, Zagreb",
    "Europe/Brussels": "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
    "Europe/Prague":
      "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    "Europe/Amsterdam":
      "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    GMT: "(GMT) Greenwich Mean Time",
    "Europe/London": "(GMT) Dublin, Edinburgh, Lisbon, London",
    "Africa/Casablanca": "(GMT) Casablanca, Monrovia",
    "Atlantic/Cape_Verde": "(GMT-01:00) Cape Verde Is.",
    "Atlantic/Azores": "(GMT-01:00) Azores",
    "America/Buenos_Aires": "(GMT-03:00) Buenos Aires, Georgetown",
    "America/Sao_Paulo": "(GMT-03:00) Brasilia",
    "America/St_Johns": "(GMT-03:30) Newfoundland",
    "America/Santiago": "(GMT-04:00) Santiago",
    "America/Caracas": "(GMT-04:00) Caracas, La Paz",
    "America/Halifax": "(GMT-04:00) Atlantic Time Canada",
    "America/Indianapolis": "(GMT-05:00) Indiana East",
    "America/New_York": "(GMT-05:00) Eastern Time US and Canada",
    "America/Bogota": "(GMT-05:00) Bogota, Lima, Quito",
    "America/Mexico_City": "(GMT-06:00) Mexico City",
    "America/Chicago": "(GMT-06:00) Central Time US and Canada",
    "America/Denver": "(GMT-07:00) Mountain Time US and Canada",
    "America/Phoenix": "(GMT-07:00) Arizona",
    "America/Los_Angeles": "(GMT-08:00) Pacific Time US and Canada",
    "America/Anchorage": "(GMT-09:00) Alaska",
    "Pacific/Honolulu": "(GMT-10:00) Hawaii",
    MIT: "(GMT-11:00) Midway Island, Samoa",
  };
  return obj;
};

export const getCountries = () => {
  var isoCountries = {
    AF: "Afghanistan",
    AX: "Aland Islands",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua And Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia",
    BA: "Bosnia And Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    CV: "Cape Verde",
    KY: "Cayman Islands",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos (Keeling) Islands",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo",
    CD: "Congo, Democratic Republic",
    CK: "Cook Islands",
    CR: "Costa Rica",
    CI: "Cote D'Ivoire",
    HR: "Croatia",
    CU: "Cuba",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Ethiopia",
    FK: "Falkland Islands (Malvinas)",
    FO: "Faroe Islands",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard Island & Mcdonald Islands",
    VA: "Holy See (Vatican City State)",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran, Islamic Republic Of",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle Of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KR: "Korea",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Lao People's Democratic Republic",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libyan Arab Jamahiriya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MK: "Macedonia",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia, Federated States Of",
    MD: "Moldova",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    AN: "Netherlands Antilles",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestinian Territory, Occupied",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RE: "Reunion",
    RO: "Romania",
    RU: "Russian Federation",
    RW: "Rwanda",
    BL: "Saint Barthelemy",
    SH: "Saint Helena",
    KN: "Saint Kitts And Nevis",
    LC: "Saint Lucia",
    MF: "Saint Martin",
    PM: "Saint Pierre And Miquelon",
    VC: "Saint Vincent And Grenadines",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome And Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia And Sandwich Isl.",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SJ: "Svalbard And Jan Mayen",
    SZ: "Swaziland",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syrian Arab Republic",
    TW: "Taiwan",
    TJ: "Tajikistan",
    TZ: "Tanzania",
    TH: "Thailand",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad And Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks And Caicos Islands",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom",
    US: "United States",
    UM: "United States Outlying Islands",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Viet Nam",
    VG: "Virgin Islands, British",
    VI: "Virgin Islands, U.S.",
    WF: "Wallis And Futuna",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
  };
  return isoCountries;
};

export const aevent_join_domains = () => {
  return {
    "joinevent.link": {
      title: "joinevent.link",
      value: "joinevent.link",
    },
    "joinclassnow.com": {
      title: "joinclassnow.com",
      value: "joinclassnow.com",
    },
    "joinlive.events": {
      title: "joinlive.events",
      value: "joinlive.events",
    },
    "joinlivecast.com": {
      title: "joinlivecast.com",
      value: "joinlivecast.com",
    },
    "livetraining.online": {
      title: "livetraining.online",
      value: "livetraining.online",
    },
    "aevent.live": {
      title: "aevent.live",
      value: "aevent.live",
    },
  };
};

export const transcoding_status = (status) => {
  switch (status) {
    case 0:
      return {
        status: "video encoded",
        color: "#16a34a",
        background: "#dcfce7",
      };
    case 1:
      return {
        status: "encoding in progress",
        color: "#2563eb",
        background: "#dbeafe",
      };
    case 2:
      return {
        status: "encoding pending",
        color: "#eab308",
        background: "#fefce8",
      };
    case 5:
      return {
        status: "encoding failed",
        color: "#f87171",
        background: "#fee2e2",
      };
  }
};

export const page_builder_type = ref([
  {
    name: "WordPress",
    radioStatus: false,
    type: "wordpress",
    img: "wordpress",
  },
  {
    name: "ClickFunnels 1.0",
    radioStatus: false,
    type: "clickfunnel_1",
    img: "clickfunnels",
  },
  {
    name: "ClickFunnels 2.0",
    radioStatus: false,
    type: "clickfunnel_2",
    img: "clickfunnels",
  },
  {
    name: "GoHighLevel",
    radioStatus: false,
    img: "gohighlevel",
    type: "gohighlevel",
  },
  {
    name: "Hubspot",
    radioStatus: false,
    img: "hubspot",
    type: "hubspot",
  },
  {
    name: "Leadpages",
    radioStatus: false,
    img: "leadpages",
    type: "leadpages",
  },
  {
    name: "Groovepages",
    radioStatus: false,
    img: "groovepages",
    type: "groovepages",
  },
  {
    name: "Unbounce",
    radioStatus: false,
    img: "unbounce",
    type: "unbounce",
  },
  {
    name: "Kajabi",
    radioStatus: false,
    img: "kajabi",
    type: "kajabi",
  },
  {
    name: "Converti",
    radioStatus: false,
    img: "converti",
    type: "converti",
  },
  {
    name: "Basichtml",
    radioStatus: false,
    img: "html",
    type: "html",
  },
  {
    name: "OntraPort",
    radioStatus: false,
    img: "ontraport",
    type: "ontraport",
  },
  {
    name: "Kartra",
    radioStatus: false,
    img: "kartra",
    type: "kartra",
  },
]);

export const resetTimelineData = {
  integration: [],
  general: {
    timelineName: "",
    description: "",
    deliveryPlatform: "",
    webinarType: "",
    eventType: "",
    organizer:[],
    timeStamp: null,
    timeZone: "America/Los_Angeles",
    visible: true,
    templateType: "",
    userGroup: [
      {
        name: "Registrants",
        id: "3",
        conditions: [],
      },
      {
        name: "Attendee",
        id: "a1",
        conditions: [
          {
            if: "attended-hour",
            condition: ">",
            duration: "0",
          },
        ],
      },
      {
        name: "Non-Attendee",
        id: "a2",
        conditions: [
          {
            if: "attended-hour",
            condition: "=",
            duration: "0",
          },
        ],
      },
    ],
    presenters: [],
  },
  registration: {
    customForms: [],
    isInnerPageEnabled: false,
    type: "multi-option",
    setupType: "page",
  },
};
export const buildInTemplates = ref([
  {
    id: "2c991992-c3e5-467d-ab48-b1968dde396c",
    removedHeader: false,
    formBody: {
      gap: "12px",
      width: "100%",
      display: "grid",
      overflow: "auto",
      background: "white",
      "box-shadow":
        "0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 5px 0px rgba(0, 0, 0, 0.06);",
      "max-height": "100%",
      "min-height": "350px",
      "margin-left": "auto",
      "padding-top": "32px",
      "margin-right": "auto",
      "padding-left": "32px",
      "border-radius": "8px",
      "padding-right": "32px",
      "padding-bottom": "32px",
      "grid-template-columns": "repeat(1,1fr)",
    },
    formItems: [
      {
        id: 1,
        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_666)">\n    <path d="M17 11V4H19V21H17V13H7V21H5V4H7V11H17Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_666">\n    <rect width="24" height="24" fill="white"/>\n    </clipPath>\n    </defs>\n    </svg>',
        link: {
          url: null,
          target: "_self",
        },
        text: "Heading",
        type: "heading",
        label: "About your business",
        style:
          "font-weight: bold; font-size: 24px; font-family: sans-serif; color: #4B5563; text-align: left; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
        styling: "global",
        fontSize: "10px",
        fontWeight: "bold",
        headingStyle: "h1",
      },
      {
        id: 12,
        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_1054)">\n    <path d="M11 5H5V19H11V5ZM13 5V19H19V5H13ZM4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_1054">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
        text: "Container",
        type: "container",
        label: "Container",
        style:
          "min-height: 70px; max-height: 100%; width: 100%; display: grid; grid-template-columns: 25% 75%; gap: 24px",
        styling: "global",
        elements: [
          {
            id: 13,
            icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_680)">\n    <path d="M11.2461 15H4.7541L2.7541 20H0.600098L7.0001 4H9.0001L15.4001 20H13.2461L11.2461 15ZM10.4461 13L8.0001 6.885L5.5541 13H10.4461ZM21.0001 12.535V12H23.0001V20H21.0001V19.465C20.392 19.8157 19.7023 20.0002 19.0003 20C18.2983 19.9998 17.6087 19.8148 17.0009 19.4637C16.393 19.1125 15.8883 18.6076 15.5373 17.9996C15.1864 17.3916 15.0017 16.702 15.0017 16C15.0017 15.298 15.1864 14.6084 15.5373 14.0004C15.8883 13.3924 16.393 12.8875 17.0009 12.5363C17.6087 12.1852 18.2983 12.0002 19.0003 12C19.7023 11.9998 20.392 12.1843 21.0001 12.535ZM19.0001 18C19.5305 18 20.0392 17.7893 20.4143 17.4142C20.7894 17.0391 21.0001 16.5304 21.0001 16C21.0001 15.4696 20.7894 14.9609 20.4143 14.5858C20.0392 14.2107 19.5305 14 19.0001 14C18.4697 14 17.961 14.2107 17.5859 14.5858C17.2108 14.9609 17.0001 15.4696 17.0001 16C17.0001 16.5304 17.2108 17.0391 17.5859 17.4142C17.961 17.7893 18.4697 18 19.0001 18Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_680">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
            text: "Label",
            type: "label",
            label: "Your business title",
            style:
              "width: 100%; margin-top: 18px; margin-bottom: 18px; font-size: 12px; line-height: 14.4px; font-weight: 500",
            styling: "global",
          },
          {
            id: 3,
            icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_103)">\n    <path d="M5 5V19H19V5H5ZM4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM9.869 15L9.049 17H6.833L11 7H13L17.167 17H14.95L14.13 15H9.87H9.869ZM10.689 13H13.311L12 9.8L10.689 13Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_103">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
            name: "name",
            text: "Text Input",
            type: "text",
            label: null,
            style:
              "font-weight: normal; font-size: 14px; font-family: sans-serif; color: #4B5563; outline: none; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
            styling: "global",
            required: false,
            inputType: "text",
            fieldStyle:
              "font-size: 14px; background-color: #ffffff; color: #6b7280; padding: 12px 16px; border-radius: 8px; border: 1px solid #cbd5e0; outline: none",
            focusStyle: "border: 1px solid #2563EB; outline: none;",
            parentStyle: null,
            placeholder: "Text input",
          },
          {
            id: 13,
            icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_680)">\n    <path d="M11.2461 15H4.7541L2.7541 20H0.600098L7.0001 4H9.0001L15.4001 20H13.2461L11.2461 15ZM10.4461 13L8.0001 6.885L5.5541 13H10.4461ZM21.0001 12.535V12H23.0001V20H21.0001V19.465C20.392 19.8157 19.7023 20.0002 19.0003 20C18.2983 19.9998 17.6087 19.8148 17.0009 19.4637C16.393 19.1125 15.8883 18.6076 15.5373 17.9996C15.1864 17.3916 15.0017 16.702 15.0017 16C15.0017 15.298 15.1864 14.6084 15.5373 14.0004C15.8883 13.3924 16.393 12.8875 17.0009 12.5363C17.6087 12.1852 18.2983 12.0002 19.0003 12C19.7023 11.9998 20.392 12.1843 21.0001 12.535ZM19.0001 18C19.5305 18 20.0392 17.7893 20.4143 17.4142C20.7894 17.0391 21.0001 16.5304 21.0001 16C21.0001 15.4696 20.7894 14.9609 20.4143 14.5858C20.0392 14.2107 19.5305 14 19.0001 14C18.4697 14 17.961 14.2107 17.5859 14.5858C17.2108 14.9609 17.0001 15.4696 17.0001 16C17.0001 16.5304 17.2108 17.0391 17.5859 17.4142C17.961 17.7893 18.4697 18 19.0001 18Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_680">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
            text: "Label",
            type: "label",
            label: "Description of business conducted",
            style:
              "width: 100%; font-size: 12px; line-height: 14.4px; font-weight: 500",
            styling: "inline",
            subText: "Helpful description",
            parentStyle: "padding-top:10px;padding-bottom:10px",
            subTextStyle:" width: 100%; font-size:12px;line-height:14.4px;color:#A3A3A3"
          },
          {
            id: 6,
            icon: '<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_5652_1161)">\n    <path d="M5.5 5V19H19.5V5H5.5ZM4.5 3H20.5C20.7652 3 21.0196 3.10536 21.2071 3.29289C21.3946 3.48043 21.5 3.73478 21.5 4V20C21.5 20.2652 21.3946 20.5196 21.2071 20.7071C21.0196 20.8946 20.7652 21 20.5 21H4.5C4.23478 21 3.98043 20.8946 3.79289 20.7071C3.60536 20.5196 3.5 20.2652 3.5 20V4C3.5 3.73478 3.60536 3.48043 3.79289 3.29289C3.98043 3.10536 4.23478 3 4.5 3ZM13.5 10V17H11.5V10H7.5V8H17.5V10H13.5Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_5652_1161">\n    <rect width="24" height="24" fill="white" transform="translate(0.5)"/>\n    </clipPath>\n    </defs>\n    </svg>',
            text: "Textarea",
            type: "textarea",
            label: null,
            style:
              "font-weight: normal; font-size: 14px; font-family: sans-serif; color: #4B5563; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
            styling: "global",
            required: false,
            fieldStyle:
              "font-size: 14px; background-color: #ffffff; color: #6b7280; padding: 10px 16px; border-radius: 8px; border: 1px solid #cbd5e0; outline: none; height: 64px; resize: none",
            parentStyle: null,
            placeholder: "I’ve typed something here",
          },
          {
            id: 13,
            icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_680)">\n    <path d="M11.2461 15H4.7541L2.7541 20H0.600098L7.0001 4H9.0001L15.4001 20H13.2461L11.2461 15ZM10.4461 13L8.0001 6.885L5.5541 13H10.4461ZM21.0001 12.535V12H23.0001V20H21.0001V19.465C20.392 19.8157 19.7023 20.0002 19.0003 20C18.2983 19.9998 17.6087 19.8148 17.0009 19.4637C16.393 19.1125 15.8883 18.6076 15.5373 17.9996C15.1864 17.3916 15.0017 16.702 15.0017 16C15.0017 15.298 15.1864 14.6084 15.5373 14.0004C15.8883 13.3924 16.393 12.8875 17.0009 12.5363C17.6087 12.1852 18.2983 12.0002 19.0003 12C19.7023 11.9998 20.392 12.1843 21.0001 12.535ZM19.0001 18C19.5305 18 20.0392 17.7893 20.4143 17.4142C20.7894 17.0391 21.0001 16.5304 21.0001 16C21.0001 15.4696 20.7894 14.9609 20.4143 14.5858C20.0392 14.2107 19.5305 14 19.0001 14C18.4697 14 17.961 14.2107 17.5859 14.5858C17.2108 14.9609 17.0001 15.4696 17.0001 16C17.0001 16.5304 17.2108 17.0391 17.5859 17.4142C17.961 17.7893 18.4697 18 19.0001 18Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_680">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
            text: "Label",
            type: "label",
            label: "Company type",
            style:
              "width: 100%; font-size: 12px; line-height: 14.4px; font-weight: 500",
            styling: "inline",
            subText: "Helpful description",
            parentStyle: "padding-top:10px;padding-bottom:10px",
            subTextStyle:" width: 100%; font-size:12px;line-height:14.4px;color:#A3A3A3",
          },
          {
            id: 14,
            icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_548)">\n    <path d="M7 7V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H21C21.2652 2 21.5196 2.10536 21.7071 2.29289C21.8946 2.48043 22 2.73478 22 3V16C22 16.2652 21.8946 16.5196 21.7071 16.7071C21.5196 16.8946 21.2652 17 21 17H17V20.993C17 21.549 16.551 22 15.993 22H3.007C2.87472 22.0001 2.74372 21.9742 2.62148 21.9236C2.49925 21.8731 2.38819 21.7989 2.29465 21.7053C2.20112 21.6118 2.12695 21.5008 2.07639 21.3785C2.02583 21.2563 1.99987 21.1253 2 20.993L2.003 8.007C2.003 7.451 2.452 7 3.01 7H7ZM9 7H15.993C16.549 7 17 7.449 17 8.007V15H20V4H9V7ZM15 9H4.003L4 20H15V9ZM8.503 18L4.967 14.464L6.381 13.05L8.503 15.172L12.745 10.929L14.159 12.343L8.503 18Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_548">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
            text: "Group Checkbox",
            type: "groupCheckbox",
            style:
              "width: 100%; font-size: 12px; line-height: 14.4px; font-weight: 500",
            label1: "Group Checkbox 1",
            label2: "Group Checkbox 2",
            label3: "Group Checkbox 3",
            options: [
              {
                label: "Group Checkbox 1",
              },
              {
                label: "Group Checkbox 2",
              },
              {
                label: "Group Checkbox 3",
              },
            ],
            styling: "global",
            parentStyle: "padding-top:18px;padding-bottom:18px",
          },
          {
            id: 13,
            icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_680)">\n    <path d="M11.2461 15H4.7541L2.7541 20H0.600098L7.0001 4H9.0001L15.4001 20H13.2461L11.2461 15ZM10.4461 13L8.0001 6.885L5.5541 13H10.4461ZM21.0001 12.535V12H23.0001V20H21.0001V19.465C20.392 19.8157 19.7023 20.0002 19.0003 20C18.2983 19.9998 17.6087 19.8148 17.0009 19.4637C16.393 19.1125 15.8883 18.6076 15.5373 17.9996C15.1864 17.3916 15.0017 16.702 15.0017 16C15.0017 15.298 15.1864 14.6084 15.5373 14.0004C15.8883 13.3924 16.393 12.8875 17.0009 12.5363C17.6087 12.1852 18.2983 12.0002 19.0003 12C19.7023 11.9998 20.392 12.1843 21.0001 12.535ZM19.0001 18C19.5305 18 20.0392 17.7893 20.4143 17.4142C20.7894 17.0391 21.0001 16.5304 21.0001 16C21.0001 15.4696 20.7894 14.9609 20.4143 14.5858C20.0392 14.2107 19.5305 14 19.0001 14C18.4697 14 17.961 14.2107 17.5859 14.5858C17.2108 14.9609 17.0001 15.4696 17.0001 16C17.0001 16.5304 17.2108 17.0391 17.5859 17.4142C17.961 17.7893 18.4697 18 19.0001 18Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_680">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
            text: "Label",
            type: "label",
            label: "Number of employees",
            style:
              "width: 100%; font-size: 12px; line-height: 14.4px; font-weight: 500",
            styling: "inline",
            subText: "Helpful description",
            parentStyle: "padding-top:10px;padding-bottom:10px",
           subTextStyle:" width: 100%; font-size:12px;line-height:14.4px;color:#A3A3A3"
          },
          {
            id: 15,
            icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_680)">\n    <path d="M11.2461 15H4.7541L2.7541 20H0.600098L7.0001 4H9.0001L15.4001 20H13.2461L11.2461 15ZM10.4461 13L8.0001 6.885L5.5541 13H10.4461ZM21.0001 12.535V12H23.0001V20H21.0001V19.465C20.392 19.8157 19.7023 20.0002 19.0003 20C18.2983 19.9998 17.6087 19.8148 17.0009 19.4637C16.393 19.1125 15.8883 18.6076 15.5373 17.9996C15.1864 17.3916 15.0017 16.702 15.0017 16C15.0017 15.298 15.1864 14.6084 15.5373 14.0004C15.8883 13.3924 16.393 12.8875 17.0009 12.5363C17.6087 12.1852 18.2983 12.0002 19.0003 12C19.7023 11.9998 20.392 12.1843 21.0001 12.535ZM19.0001 18C19.5305 18 20.0392 17.7893 20.4143 17.4142C20.7894 17.0391 21.0001 16.5304 21.0001 16C21.0001 15.4696 20.7894 14.9609 20.4143 14.5858C20.0392 14.2107 19.5305 14 19.0001 14C18.4697 14 17.961 14.2107 17.5859 14.5858C17.2108 14.9609 17.0001 15.4696 17.0001 16C17.0001 16.5304 17.2108 17.0391 17.5859 17.4142C17.961 17.7893 18.4697 18 19.0001 18Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_680">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
            text: "Radio Buttons",
            type: "radioButtons",
            style:
              "width: 112px; font-size: 12px; line-height: 14.4px; font-weight: 500; border: 1px solid #e5e5e5; border-radius: 4px",
            label1: "1-20",
            label2: "21-40",
            label3: "50+",
            styling: "global",
            parentStyle: "padding-top:18px;padding-bottom:18px",
       
          },
          {
            id: 13,
            icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_680)">\n    <path d="M11.2461 15H4.7541L2.7541 20H0.600098L7.0001 4H9.0001L15.4001 20H13.2461L11.2461 15ZM10.4461 13L8.0001 6.885L5.5541 13H10.4461ZM21.0001 12.535V12H23.0001V20H21.0001V19.465C20.392 19.8157 19.7023 20.0002 19.0003 20C18.2983 19.9998 17.6087 19.8148 17.0009 19.4637C16.393 19.1125 15.8883 18.6076 15.5373 17.9996C15.1864 17.3916 15.0017 16.702 15.0017 16C15.0017 15.298 15.1864 14.6084 15.5373 14.0004C15.8883 13.3924 16.393 12.8875 17.0009 12.5363C17.6087 12.1852 18.2983 12.0002 19.0003 12C19.7023 11.9998 20.392 12.1843 21.0001 12.535ZM19.0001 18C19.5305 18 20.0392 17.7893 20.4143 17.4142C20.7894 17.0391 21.0001 16.5304 21.0001 16C21.0001 15.4696 20.7894 14.9609 20.4143 14.5858C20.0392 14.2107 19.5305 14 19.0001 14C18.4697 14 17.961 14.2107 17.5859 14.5858C17.2108 14.9609 17.0001 15.4696 17.0001 16C17.0001 16.5304 17.2108 17.0391 17.5859 17.4142C17.961 17.7893 18.4697 18 19.0001 18Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_680">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
            text: "Label",
            type: "label",
            label: "Business address",
            style:
              "width: 100%; font-size: 12px; line-height: 14.4px; font-weight: 500",
            styling: "inline",
            subText: "Helpful description",
            parentStyle: "padding-top:10px;padding-bottom:10px",
            subTextStyle:" width: 100%; font-size:12px;line-height:14.4px;color:#A3A3A3"
          },
          {
            id: 16,
            icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_103)">\n    <path d="M5 5V19H19V5H5ZM4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM9.869 15L9.049 17H6.833L11 7H13L17.167 17H14.95L14.13 15H9.87H9.869ZM10.689 13H13.311L12 9.8L10.689 13Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_103">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
            text: "Group Inputs",
            type: "groupInputs",
            label: "Group Inputs",
            style:
              "font-weight: normal; font-size: 14px; font-family: sans-serif; color: #4B5563; outline: none; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
            styling: "global",
            required: false,
            inputType: "email",
            fieldStyle:
              "font-size: 14px; background-color: #ffffff; color: #dc2626; padding: 12px 16px; border-radius: 8px; border: 1px solid #c9c9d9; outline: none",
            focusStyle: "border: 1px solid #2563EB; outline: none;",
            inputType1: "text",
            parentStyle: null,
            placeholder: "Enter Email here",
            placeholder1: "Start writing here...",
    
          },
          {
            id: 13,
            icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_680)">\n    <path d="M11.2461 15H4.7541L2.7541 20H0.600098L7.0001 4H9.0001L15.4001 20H13.2461L11.2461 15ZM10.4461 13L8.0001 6.885L5.5541 13H10.4461ZM21.0001 12.535V12H23.0001V20H21.0001V19.465C20.392 19.8157 19.7023 20.0002 19.0003 20C18.2983 19.9998 17.6087 19.8148 17.0009 19.4637C16.393 19.1125 15.8883 18.6076 15.5373 17.9996C15.1864 17.3916 15.0017 16.702 15.0017 16C15.0017 15.298 15.1864 14.6084 15.5373 14.0004C15.8883 13.3924 16.393 12.8875 17.0009 12.5363C17.6087 12.1852 18.2983 12.0002 19.0003 12C19.7023 11.9998 20.392 12.1843 21.0001 12.535ZM19.0001 18C19.5305 18 20.0392 17.7893 20.4143 17.4142C20.7894 17.0391 21.0001 16.5304 21.0001 16C21.0001 15.4696 20.7894 14.9609 20.4143 14.5858C20.0392 14.2107 19.5305 14 19.0001 14C18.4697 14 17.961 14.2107 17.5859 14.5858C17.2108 14.9609 17.0001 15.4696 17.0001 16C17.0001 16.5304 17.2108 17.0391 17.5859 17.4142C17.961 17.7893 18.4697 18 19.0001 18Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_680">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
            text: "Label",
            type: "label",
            label: "Billing address",
            style:
              "width: 100%; font-size: 12px; line-height: 14.4px; font-weight: 500",
            styling: "global",
            subText: "Helpful description",
            parentStyle: "padding-top:10px;padding-bottom:10px",
            subTextStyle:" width: 100%; font-size:12px;line-height:14.4px;color:#A3A3A3"
          },
          {
            id: 8,
            icon: '<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_5652_1197)">\n    <path d="M4.5 3H20.5C20.7652 3 21.0196 3.10536 21.2071 3.29289C21.3946 3.48043 21.5 3.73478 21.5 4V20C21.5 20.2652 21.3946 20.5196 21.2071 20.7071C21.0196 20.8946 20.7652 21 20.5 21H4.5C4.23478 21 3.98043 20.8946 3.79289 20.7071C3.60536 20.5196 3.5 20.2652 3.5 20V4C3.5 3.73478 3.60536 3.48043 3.79289 3.29289C3.98043 3.10536 4.23478 3 4.5 3ZM5.5 5V19H19.5V5H5.5ZM11.503 16L7.26 11.757L8.674 10.343L11.503 13.172L17.159 7.515L18.574 8.929L11.503 16Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_5652_1197">\n    <rect width="24" height="24" fill="white" transform="translate(0.5)"/>\n    </clipPath>\n    </defs>\n    </svg>',
            name: "agree",
            text: "Checkbox",
            type: "checkbox",
            label: "Same as business address",
            style:
              "font-weight: normal; font-size: 12px; font-family: sans-serif; color: #4B5563; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
            heading: null,
            styling: "global",
            fieldStyle:
              "font-size: 18px; font-family: sans-serif; color: #4B5563; font-weight: 600",
            parentStyle: null,
         
          },
        ],
        formBody: {
          gap: "12px",
          "max-height": "100%",
          "min-height": "80px",
          "padding-top": "16px",
          "padding-left": "16px",
          "padding-right": "16px",
          "padding-bottom": "16px",
        },
      },
      {
        id: 10,
        url: "/",
        icon: '<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_5652_1154)">\n    <path d="M20.106 6.995C20.03 6.697 19.814 6.472 19.567 6.403C19.13 6.28 17 6 12.5 6C8 6 5.872 6.28 5.431 6.403C5.187 6.471 4.971 6.696 4.894 6.995C4.785 7.419 4.5 9.196 4.5 12C4.5 14.804 4.785 16.58 4.894 17.006C4.97 17.303 5.186 17.528 5.432 17.596C5.872 17.72 8 18 12.5 18C17 18 19.129 17.72 19.569 17.597C19.813 17.529 20.029 17.304 20.106 17.005C20.215 16.581 20.5 14.8 20.5 12C20.5 9.2 20.215 7.42 20.106 6.995ZM22.043 6.498C22.5 8.28 22.5 12 22.5 12C22.5 12 22.5 15.72 22.043 17.502C21.789 18.487 21.046 19.262 20.105 19.524C18.396 20 12.5 20 12.5 20C12.5 20 6.607 20 4.895 19.524C3.95 19.258 3.208 18.484 2.957 17.502C2.5 15.72 2.5 12 2.5 12C2.5 12 2.5 8.28 2.957 6.498C3.211 5.513 3.954 4.738 4.895 4.476C6.607 4 12.5 4 12.5 4C12.5 4 18.396 4 20.105 4.476C21.05 4.742 21.792 5.516 22.043 6.498Z" fill="currentColor"/>\n    <path d="M13.5 11V8H11.5V11H8.5V13H11.499L11.5 16H13.5L13.499 13H16.5V11H13.5Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_5652_1154">\n    <rect width="24" height="24" fill="white" transform="translate(0.5)"/>\n    </clipPath>\n    </defs>\n    </svg>',
        text: "Button",
        type: "button",
        label: "Submit",
        style:
          "font-weight: bold; font-size: 14px; font-family: sans-serif; cursor: pointer; background: #2563eb; color: white; border-radius: 8px; width: 100%; border: 1px solid #2563eb; text-align: center; align-self: start; padding-top: 8px; padding-bottom: 8px; padding-left: 16px; padding-right: 16px; box-shadow: none",
        styling: "global",
        required: false,
    
      },
    ],
    backgroundColor: "#fffff",
    updatedDate: "2024-10-21 10:25:35",
    created_at: "2024-05-16T11:53:38.000000Z",
    updated_at: "2024-10-21T10:25:36.000000Z",
    templateName: "Untitled Form",
  },
  {
    id: "3b4f6e72-d2fa-4c3b-b4c3-fe285a941713",
    removedHeader: false,
    formBody: {
      gap: "12px",
      width: "100%",
      display: "grid",
      overflow: "auto",
      background: "white",
      "box-shadow":
        "0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 5px 0px rgba(0, 0, 0, 0.06);",
      "max-height": "100%",
      "min-height": "350px",
      "margin-left": "auto",
      "padding-top": "32px",
      "margin-right": "auto",
      "padding-left": "32px",
      "border-radius": "8px",
      "padding-right": "32px",
      "padding-bottom": "32px",
      "grid-template-columns": "repeat(1,1fr)",
    },
    formItems: [
      {
        id: 1,
        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_666)">\n    <path d="M17 11V4H19V21H17V13H7V21H5V4H7V11H17Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_666">\n    <rect width="24" height="24" fill="white"/>\n    </clipPath>\n    </defs>\n    </svg>',
        link: {
          url: null,
          target: "_self",
        },
        text: "Heading",
        type: "heading",
        label: "Industry-Specific Registration Form",
        style:
          "font-weight: bold; font-size: 24px; font-family: sans-serif; color: #4B5563; text-align: left; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
        styling: "global",
        fontSize: "10px",
        fontWeight: "bold",
        headingStyle: "h1",
       
      },
      {
        id: 2,
        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_680)">\n    <path d="M11.2461 15H4.7541L2.7541 20H0.600098L7.0001 4H9.0001L15.4001 20H13.2461L11.2461 15ZM10.4461 13L8.0001 6.885L5.5541 13H10.4461ZM21.0001 12.535V12H23.0001V20H21.0001V19.465C20.392 19.8157 19.7023 20.0002 19.0003 20C18.2983 19.9998 17.6087 19.8148 17.0009 19.4637C16.393 19.1125 15.8883 18.6076 15.5373 17.9996C15.1864 17.3916 15.0017 16.702 15.0017 16C15.0017 15.298 15.1864 14.6084 15.5373 14.0004C15.8883 13.3924 16.393 12.8875 17.0009 12.5363C17.6087 12.1852 18.2983 12.0002 19.0003 12C19.7023 11.9998 20.392 12.1843 21.0001 12.535ZM19.0001 18C19.5305 18 20.0392 17.7893 20.4143 17.4142C20.7894 17.0391 21.0001 16.5304 21.0001 16C21.0001 15.4696 20.7894 14.9609 20.4143 14.5858C20.0392 14.2107 19.5305 14 19.0001 14C18.4697 14 17.961 14.2107 17.5859 14.5858C17.2108 14.9609 17.0001 15.4696 17.0001 16C17.0001 16.5304 17.2108 17.0391 17.5859 17.4142C17.961 17.7893 18.4697 18 19.0001 18Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_680">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
        link: {
          url: null,
          target: "_self",
        },
        text: "Paragraph",
        type: "paragraph",
        label:
          "Tailored for webinars targeting specific industries, collecting relevant professional details.",
        style:
          "width: 100%; font-weight: normal; font-size: 14px; font-family: sans-serif; color: #6b7280; text-align: left; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
        styling: "global",
      
      },
      {
        id: 3,
        icon: "/icons/title_icon.png",
        name: "name",
        text: "Text Input",
        type: "text",
        label: "Full Name",
        style:
          "font-weight: normal; font-size: 14px; font-family: sans-serif; color: #4B5563; outline: none; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
        styling: "global",
        required: false,
        inputType: "text",
        removable: false,
        fieldStyle:
          "font-size: 14px; background-color: #ffffff; color: #6b7280; padding: 12px 16px; border-radius: 8px; border: 1px solid #cbd5e0; outline: none",
        focusStyle: "border: 1px solid #2563EB; outline: none;",
        parentStyle: null,
        placeholder: "Full name",
        singleField: true,
     
      },
      {
        id: 3,
        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_103)">\n    <path d="M5 5V19H19V5H5ZM4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM9.869 15L9.049 17H6.833L11 7H13L17.167 17H14.95L14.13 15H9.87H9.869ZM10.689 13H13.311L12 9.8L10.689 13Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_103">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
        text: "Text Input",
        type: "text",
        label: "Email",
        style:
          "font-weight: normal; font-size: 14px; font-family: sans-serif; color: #4B5563; outline: none; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
        styling: "global",
        required: false,
        inputType: "email",
        fieldStyle:
          "font-size: 14px; background-color: #ffffff; color: #6b7280; padding: 12px 16px; border-radius: 8px; border: 1px solid #cbd5e0; outline: none",
        focusStyle: "border: 1px solid #2563EB; outline: none;",
        parentStyle: null,
        placeholder: "youremail@example.com",
    
      },
      {
        id: 3,
        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_103)">\n    <path d="M5 5V19H19V5H5ZM4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM9.869 15L9.049 17H6.833L11 7H13L17.167 17H14.95L14.13 15H9.87H9.869ZM10.689 13H13.311L12 9.8L10.689 13Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_103">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
        text: "Text Input",
        type: "text",
        label: "Company Name",
        style:
          "font-weight: normal; font-size: 14px; font-family: sans-serif; color: #4B5563; outline: none; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
        styling: "global",
        required: false,
        inputType: "text",
        fieldStyle:
          "font-size: 14px; background-color: #ffffff; color: #6b7280; padding: 12px 16px; border-radius: 8px; border: 1px solid #cbd5e0; outline: none",
        focusStyle: "border: 1px solid #2563EB; outline: none;",
        parentStyle: null,
        placeholder: "Your Company Name",
      
      },
      {
        id: 3,
        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_103)">\n    <path d="M5 5V19H19V5H5ZM4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM9.869 15L9.049 17H6.833L11 7H13L17.167 17H14.95L14.13 15H9.87H9.869ZM10.689 13H13.311L12 9.8L10.689 13Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_103">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
        text: "Text Input",
        type: "text",
        label: "Job Title",
        style:
          "font-weight: normal; font-size: 14px; font-family: sans-serif; color: #4B5563; outline: none; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
        styling: "inline",
        required: false,
        inputType: "text",
        fieldStyle:
          "font-size: 14px; background-color: #ffffff; color: #6b7280; padding: 12px 16px; border-radius: 8px; border: 1px solid #cbd5e0; outline: none",
        focusStyle: "border: 1px solid #2563EB; outline: none;",
        parentStyle: null,
        placeholder: "Enter Job Title",
      
      },
      {
        id: 4,
        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n      <g clip-path="url(#clip0_16_174)">\n      <path d="M12 13.172L16.95 8.22198L18.364 9.63598L12 16L5.63599 9.63598L7.04999 8.22198L12 13.172Z" fill="currentColor"/>\n      </g>\n      <defs>\n      <clipPath id="clip0_16_174">\n      <rect width="24" height="24" fill="currentColor"/>\n      </clipPath>\n      </defs>\n      </svg>',
        name: null,
        text: "Select",
        type: "select",
        label: "Industry",
        style:
          "font-weight: normal; font-size: 14px; font-family: sans-serif; color: #4B5563; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
        options: [
          {
            label: "Select a Industry Type",
            value: "option1",
          },
          {
            label: "Option 2",
            value: "option2",
          },
        ],
        styling: "global",
        multiple: false,
        required: false,
        fieldStyle:
          "padding: 12px 16px; border: 1px solid #d4d4d4; border-radius: 6px; background-color: transparent",
        parentStyle: "flex-direction: column",
        placeholder: "Start writing here...",
      },
      {
        id: 3,
        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_103)">\n    <path d="M5 5V19H19V5H5ZM4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM9.869 15L9.049 17H6.833L11 7H13L17.167 17H14.95L14.13 15H9.87H9.869ZM10.689 13H13.311L12 9.8L10.689 13Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_103">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
        text: "Text Input",
        type: "text",
        label: "Years of Experience in the industry",
        style:
          "font-weight: normal; font-size: 14px; font-family: sans-serif; color: #4B5563; outline: none; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
        styling: "inline",
        required: false,
        inputType: "text",
        fieldStyle:
          "font-size: 14px; background-color: #ffffff; color: #6b7280; padding: 12px 16px; border-radius: 8px; border: 1px solid #cbd5e0; outline: none",
        focusStyle: "border: 1px solid #2563EB; outline: none;",
        parentStyle: "flex-direction: column",
        placeholder: "Years of experience",
    
      },
      {
        id: 4,
        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n      <g clip-path="url(#clip0_16_174)">\n      <path d="M12 13.172L16.95 8.22198L18.364 9.63598L12 16L5.63599 9.63598L7.04999 8.22198L12 13.172Z" fill="currentColor"/>\n      </g>\n      <defs>\n      <clipPath id="clip0_16_174">\n      <rect width="24" height="24" fill="currentColor"/>\n      </clipPath>\n      </defs>\n      </svg>',
        text: "Select",
        type: "select",
        label: "Professional Certifications (optional)",
        style:
          "font-weight: normal; font-size: 14px; font-family: sans-serif; color: #4B5563; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
        options: [
          {
            label: "Select Certifications",
            value: "option1",
          },
          {
            label: "Option 2",
            value: "option2",
          },
        ],
        styling: "inline",
        multiple: false,
        required: false,
        fieldStyle:
          "padding: 12px 16px; border: 1px solid #d4d4d4; border-radius: 6px; background-color: transparent",
        parentStyle: "flex-direction: column",
        placeholder: "Start writing here...",
    
      },
      {
        id: 8,
        icon: '<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_5652_1197)">\n    <path d="M4.5 3H20.5C20.7652 3 21.0196 3.10536 21.2071 3.29289C21.3946 3.48043 21.5 3.73478 21.5 4V20C21.5 20.2652 21.3946 20.5196 21.2071 20.7071C21.0196 20.8946 20.7652 21 20.5 21H4.5C4.23478 21 3.98043 20.8946 3.79289 20.7071C3.60536 20.5196 3.5 20.2652 3.5 20V4C3.5 3.73478 3.60536 3.48043 3.79289 3.29289C3.98043 3.10536 4.23478 3 4.5 3ZM5.5 5V19H19.5V5H5.5ZM11.503 16L7.26 11.757L8.674 10.343L11.503 13.172L17.159 7.515L18.574 8.929L11.503 16Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_5652_1197">\n    <rect width="24" height="24" fill="white" transform="translate(0.5)"/>\n    </clipPath>\n    </defs>\n    </svg>',
        name: "agree",
        text: "Checkbox",
        type: "checkbox",
        label: "Yes, please send me news and insights directly to my inbox!",
        style:
          "font-weight: normal; font-size: 12px; font-family: sans-serif; color: #4B5563; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
        heading: null,
        styling: "inline",
        fieldStyle:
          "font-size: 18px; font-family: sans-serif; color: #4B5563; font-weight: 600",
        parentStyle: null,
       
      },
      {
        id: 9,
        url: "/",
        icon: "/icons/button_icon.png",
        text: "Button",
        type: "button",
        label: "Submit",
        style:
          "font-weight: 500; font-size: 14px; font-family: sans-serif; cursor: pointer; background: #2563eb; color: white; border-radius: 8px; width: 100%; border: 1px solid #2563eb; text-align: center; align-self: start; padding-top: 8px; padding-bottom: 8px; padding-left: 16px; padding-right: 16px; box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 5px 0px rgba(0, 0, 0, 0.06)",
        styling: "inline",
        removable: true,
      
      },
    ],
    backgroundColor: "rgba(37, 99, 235,0.3)",
    updatedDate: "2024-10-16 14:11:15",
    created_at: "2024-05-16T11:53:02.000000Z",
    updated_at: "2024-10-16T14:11:14.000000Z",
    templateName: "Untitled Form",
  },
  {
    id: "7baa0d82-2166-456a-ae38-f7d3aeecfb10",
    removedHeader: true,
    formBody: {
      gap: "12px",
      width: "100%",
      display: "grid",
      overflow: "auto",
      background: "white",
      "box-shadow":
        "0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 5px 0px rgba(0, 0, 0, 0.06);",
      "max-height": "100%",
      "min-height": "350px",
      "margin-left": "auto",
      "padding-top": "32px",
      "margin-right": "auto",
      "padding-left": "32px",
      "border-radius": "8px",
      "padding-right": "32px",
      "padding-bottom": "32px",
      "grid-template-columns": "repeat(1,1fr)",
    },
    formItems: [
      {
        id: 1,
        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_666)">\n    <path d="M17 11V4H19V21H17V13H7V21H5V4H7V11H17Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_666">\n    <rect width="24" height="24" fill="white"/>\n    </clipPath>\n    </defs>\n    </svg>',
        link: {
          url: null,
          target: "_self",
        },
        text: "Event Title",
        type: "heading",
        label: "Pre-Webinar Survey Form",
        style:
          "font-weight: bold; font-size: 24px; font-family: sans-serif; color: #4B5563; text-align: left; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
        styling: "inline",
        fontSize: "10px",
        eventTitle: true,
        fontWeight: "bold",
        dynamicData: true,
        headingStyle: "h1",
      
      },
      {
        id: 2,
        text: "Paragraph",
        type: "paragraph",
        label:
          "Gather insights on attendees' expectations and existing knowledge to tailor webinar content accordingly.",
        style:
          "font-weight: normal; font-size: 14px; font-family: sans-serif; color: #6b7280; text-align: left; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px; pointer-events: none; cursor: default; word-break: break-words",
        styling: "global",
        eventBody: true,
        dynamicBody: "{{!webinar-body}}",
   
      },
      {
        id: 3,
        icon: "/icons/title_icon.png",
        name: "name",
        text: "Text Input",
        type: "text",
        label: "Full Name",
        style:
          "font-weight: normal; font-size: 14px; font-family: sans-serif; color: #4B5563; outline: none; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
        styling: "global",
        required: true,
        inputType: "text",
        removable: false,
        fieldStyle:
          "font-size: 14px; background-color: #ffffff; color: #6b7280; padding: 12px 16px; border-radius: 8px; border: 1px solid #cbd5e0; outline: none",
        focusStyle: "border: 1px solid #2563EB; outline: none;",
        parentStyle: null,
        placeholder: "Full name",
        singleField: true,
      
      },
      {
        id: 3,
        icon: "/icons/title_icon.png",
        name: "email",
        text: "Text Input",
        type: "text",
        label: "Email",
        style:
          "font-weight: normal; font-size: 14px; font-family: sans-serif; color: #4B5563; outline: none; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
        styling: "global",
        required: true,
        inputType: "text",
        removable: false,
        fieldStyle:
          "font-size: 14px; background-color: #ffffff; color: #6b7280; padding: 12px 16px; border-radius: 8px; border: 1px solid #cbd5e0; outline: none",
        focusStyle: "border: 1px solid #2563EB; outline: none;",
        parentStyle: null,
        placeholder: "youremail@example.com",
       
      },
      {
        id: 3,
        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_103)">\n    <path d="M5 5V19H19V5H5ZM4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM9.869 15L9.049 17H6.833L11 7H13L17.167 17H14.95L14.13 15H9.87H9.869ZM10.689 13H13.311L12 9.8L10.689 13Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_103">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
        text: "Text Input",
        type: "text",
        label: "Webinar Topic",
        style:
          "font-weight: normal; font-size: 14px; font-family: sans-serif; color: #4B5563; outline: none; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
        styling: "global",
        required: false,
        inputType: "text",
        fieldStyle:
          "font-size: 14px; background-color: #ffffff; color: #6b7280; padding: 12px 16px; border-radius: 8px; border: 1px solid #cbd5e0; outline: none",
        focusStyle: "border: 1px solid #2563EB; outline: none;",
        parentStyle: null,
        placeholder: "Likert scale",
      
      },
      {
        id: 6,
        icon: '<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_5652_1161)">\n    <path d="M5.5 5V19H19.5V5H5.5ZM4.5 3H20.5C20.7652 3 21.0196 3.10536 21.2071 3.29289C21.3946 3.48043 21.5 3.73478 21.5 4V20C21.5 20.2652 21.3946 20.5196 21.2071 20.7071C21.0196 20.8946 20.7652 21 20.5 21H4.5C4.23478 21 3.98043 20.8946 3.79289 20.7071C3.60536 20.5196 3.5 20.2652 3.5 20V4C3.5 3.73478 3.60536 3.48043 3.79289 3.29289C3.98043 3.10536 4.23478 3 4.5 3ZM13.5 10V17H11.5V10H7.5V8H17.5V10H13.5Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_5652_1161">\n    <rect width="24" height="24" fill="white" transform="translate(0.5)"/>\n    </clipPath>\n    </defs>\n    </svg>',
        text: "Textarea",
        type: "textarea",
        label: "Specific Questions You Want Addressed",
        style:
          "font-weight: normal; font-size: 14px; font-family: sans-serif; color: #4B5563; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
        styling: "global",
        required: false,
        fieldStyle:
          "font-size: 14px; background-color: #ffffff; color: #6b7280; padding: 10px 16px; border-radius: 8px; border: 1px solid #cbd5e0; outline: none; height: 100px; resize: none",
        parentStyle: "flex-direction: column",
        placeholder: "Start writing here...",
        
      },
      {
        id: 4,
        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n      <g clip-path="url(#clip0_16_174)">\n      <path d="M12 13.172L16.95 8.22198L18.364 9.63598L12 16L5.63599 9.63598L7.04999 8.22198L12 13.172Z" fill="currentColor"/>\n      </g>\n      <defs>\n      <clipPath id="clip0_16_174">\n      <rect width="24" height="24" fill="currentColor"/>\n      </clipPath>\n      </defs>\n      </svg>',
        text: "Select",
        type: "select",
        label: "Preferred Learning Format",
        style:
          "font-weight: normal; font-size: 14px; font-family: sans-serif; color: #4B5563; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
        options: [
          {
            label: "Select Learning Format",
            value: "option1",
          },
          {
            label: "Option 2",
            value: "option2",
          },
        ],
        styling: "global",
        multiple: false,
        required: false,
        fieldStyle:
          "padding: 12px 16px; border: 1px solid #d4d4d4; border-radius: 6px; background-color: transparent",
        parentStyle: null,
        placeholder: "Start writing here...",
      
      },
      {
        id: 14,
        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_548)">\n    <path d="M7 7V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H21C21.2652 2 21.5196 2.10536 21.7071 2.29289C21.8946 2.48043 22 2.73478 22 3V16C22 16.2652 21.8946 16.5196 21.7071 16.7071C21.5196 16.8946 21.2652 17 21 17H17V20.993C17 21.549 16.551 22 15.993 22H3.007C2.87472 22.0001 2.74372 21.9742 2.62148 21.9236C2.49925 21.8731 2.38819 21.7989 2.29465 21.7053C2.20112 21.6118 2.12695 21.5008 2.07639 21.3785C2.02583 21.2563 1.99987 21.1253 2 20.993L2.003 8.007C2.003 7.451 2.452 7 3.01 7H7ZM9 7H15.993C16.549 7 17 7.449 17 8.007V15H20V4H9V7ZM15 9H4.003L4 20H15V9ZM8.503 18L4.967 14.464L6.381 13.05L8.503 15.172L12.745 10.929L14.159 12.343L8.503 18Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_548">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
        text: "Group Checkbox",
        type: "groupCheckbox",
        style:
          "width: 100%; font-size: 12px; line-height: 14.4px; font-weight: 500",
        label1: "Group Checkbox 1",
        label2: "Group Checkbox 2",
        label3: "Group Checkbox 3",
        heading: "Topics of Interest",
        options: [
          {
            label: "Industry Trends",
          },
          {
            label: "Best Practices",
          },
          {
            label: "Tools and Technologies",
          },
          {
            label: "Regulatory Changes",
            value: "option4",
          },
        ],
        styling: "global",
        parentStyle: "padding-top:18px;padding-bottom:18px",
    
      },
      {
        id: 14,
        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_548)">\n    <path d="M7 7V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H21C21.2652 2 21.5196 2.10536 21.7071 2.29289C21.8946 2.48043 22 2.73478 22 3V16C22 16.2652 21.8946 16.5196 21.7071 16.7071C21.5196 16.8946 21.2652 17 21 17H17V20.993C17 21.549 16.551 22 15.993 22H3.007C2.87472 22.0001 2.74372 21.9742 2.62148 21.9236C2.49925 21.8731 2.38819 21.7989 2.29465 21.7053C2.20112 21.6118 2.12695 21.5008 2.07639 21.3785C2.02583 21.2563 1.99987 21.1253 2 20.993L2.003 8.007C2.003 7.451 2.452 7 3.01 7H7ZM9 7H15.993C16.549 7 17 7.449 17 8.007V15H20V4H9V7ZM15 9H4.003L4 20H15V9ZM8.503 18L4.967 14.464L6.381 13.05L8.503 15.172L12.745 10.929L14.159 12.343L8.503 18Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_548">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
        text: "Group Checkbox",
        type: "groupCheckbox",
        style:
          "width: 100%; font-size: 12px; line-height: 14.4px; font-weight: 500",
        label1: "Industry Trends",
        label2: "Best Practices",
        label3: "Tools and Technologies",
        styling: "global",
        parentStyle: "padding-top:18px;padding-bottom:18px",
      
      },
      {
        id: 9,
        url: "/",
        icon: "/icons/button_icon.png",
        text: "Button",
        type: "button",
        label: "Submit",
        style:
          "font-weight: 500; font-size: 14px; font-family: sans-serif; cursor: pointer; background: #2563eb; color: white; border-radius: 8px; width: 100%; border: 1px solid #2563eb; text-align: center; align-self: start; padding-top: 8px; padding-bottom: 8px; padding-left: 16px; padding-right: 16px; box-shadow: none",
        styling: "global",
        removable: true,
      
      },
    ],
    backgroundColor: "rgba(37, 99, 235,0.3)",
    updatedDate: "2024-10-16 15:28:23",
    created_at: "2024-05-16T11:53:56.000000Z",
    updated_at: "2024-10-16T15:28:22.000000Z",
    templateName: "Untitled Form",
  },
  {
    id: "9289d8b7-81f4-4a4f-ba95-bfd0dc2f6c79",
    removedHeader: false,
    formBody: {
      gap: "12px",
      width: "100%",
      display: "grid",
      overflow: "auto",
      background: "white",
      "box-shadow":
        "0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 5px 0px rgba(0, 0, 0, 0.06);",
      "max-height": "100%",
      "min-height": "350px",
      "margin-left": "auto",
      "padding-top": "32px",
      "margin-right": "auto",
      "padding-left": "32px",
      "border-radius": "8px",
      "padding-right": "32px",
      "padding-bottom": "32px",
      "grid-template-columns": "repeat(1,1fr)",
    },
    formItems: [
      {
        id: 12,
        icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_1054)">\n    <path d="M11 5H5V19H11V5ZM13 5V19H19V5H13ZM4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_1054">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
        text: "Container",
        type: "container",
        label: "Container",
        style:
          "min-height: 70px; max-height: 100%; width: 100%; display: grid; grid-template-columns: repeat(2, 1fr); gap: 32px",
        styling: "global",
        elements: [
          {
            id: 12,
            icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_1054)">\n    <path d="M11 5H5V19H11V5ZM13 5V19H19V5H13ZM4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_1054">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
            text: "Container",
            type: "container",
            label: "Container",
            style:
              "min-height: 70px; max-height: 100%; width: 100%; display: grid; grid-template-columns: 100%; gap: 4px",
            styling: "inline",
            elements: [
              {
                id: 1,
                icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_666)">\n    <path d="M17 11V4H19V21H17V13H7V21H5V4H7V11H17Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_666">\n    <rect width="24" height="24" fill="white"/>\n    </clipPath>\n    </defs>\n    </svg>',
                link: {
                  url: null,
                  target: "_self",
                },
                text: "Heading",
                type: "heading",
                label: "Tell us a little about your store",
                style:
                  "font-weight: bold; font-size: 24px; font-family: sans-serif; color: #4B5563; text-align: left; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px;",
                styling: "global",
                fontSize: "10px",
                fontWeight: "bold",
                headingStyle: "h1",
              },
              {
                id: 2,
                icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_680)">\n    <path d="M11.2461 15H4.7541L2.7541 20H0.600098L7.0001 4H9.0001L15.4001 20H13.2461L11.2461 15ZM10.4461 13L8.0001 6.885L5.5541 13H10.4461ZM21.0001 12.535V12H23.0001V20H21.0001V19.465C20.392 19.8157 19.7023 20.0002 19.0003 20C18.2983 19.9998 17.6087 19.8148 17.0009 19.4637C16.393 19.1125 15.8883 18.6076 15.5373 17.9996C15.1864 17.3916 15.0017 16.702 15.0017 16C15.0017 15.298 15.1864 14.6084 15.5373 14.0004C15.8883 13.3924 16.393 12.8875 17.0009 12.5363C17.6087 12.1852 18.2983 12.0002 19.0003 12C19.7023 11.9998 20.392 12.1843 21.0001 12.535ZM19.0001 18C19.5305 18 20.0392 17.7893 20.4143 17.4142C20.7894 17.0391 21.0001 16.5304 21.0001 16C21.0001 15.4696 20.7894 14.9609 20.4143 14.5858C20.0392 14.2107 19.5305 14 19.0001 14C18.4697 14 17.961 14.2107 17.5859 14.5858C17.2108 14.9609 17.0001 15.4696 17.0001 16C17.0001 16.5304 17.2108 17.0391 17.5859 17.4142C17.961 17.7893 18.4697 18 19.0001 18Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_680">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
                link: {
                  url: null,
                  target: "_self",
                },
                text: "Paragraph",
                type: "paragraph",
                label:
                  "This is an initial information of your business . You can change it anytime.",
                style:
                  "width:100%;font-weight: normal; font-size: 14px; font-family: sans-serif; color: #6b7280; text-align: left; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px;",
                styling: "global",
              },
              {
                id: 3,
                icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_103)">\n    <path d="M5 5V19H19V5H5ZM4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM9.869 15L9.049 17H6.833L11 7H13L17.167 17H14.95L14.13 15H9.87H9.869ZM10.689 13H13.311L12 9.8L10.689 13Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_103">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
                text: "Text Input",
                type: "text",
                label: null,
                style:
                  "font-weight: normal; font-size: 14px; font-family: sans-serif; color: #4B5563; outline: none; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
                styling: "global",
                required: false,
                inputType: "text",
                fieldStyle:
                  "font-size: 14px; background-color: #ffffff; color: #6b7280; padding: 12px 16px; border-radius: 8px; border: 1px solid #cbd5e0; outline: none",
                focusStyle: "border: 1px solid #2563EB; outline: none;",
                parentStyle: null,
                placeholder: "Store Name",
               
              },
              {
                id: 4,
                icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n      <g clip-path="url(#clip0_16_174)">\n      <path d="M12 13.172L16.95 8.22198L18.364 9.63598L12 16L5.63599 9.63598L7.04999 8.22198L12 13.172Z" fill="currentColor"/>\n      </g>\n      <defs>\n      <clipPath id="clip0_16_174">\n      <rect width="24" height="24" fill="currentColor"/>\n      </clipPath>\n      </defs>\n      </svg>',
                text: "Select",
                type: "select",
                label: null,
                style:
                  "font-weight: normal; font-size: 14px; font-family: sans-serif; color: #4B5563; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
                options: [
                  {
                    label: "Industry",
                    value: "option1",
                  },
                  {
                    label: "Option 2",
                    value: "option2",
                  },
                ],
                styling: "inline",
                multiple: false,
                required: false,
                fieldStyle:
                  "padding: 12px 16px; border: 1px solid #d4d4d4; border-radius: 6px; background-color: transparent",
                parentStyle: null,
                placeholder: "Start writing here...",
             
              },
              {
                id: 8,
                icon: '<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_5652_1197)">\n    <path d="M4.5 3H20.5C20.7652 3 21.0196 3.10536 21.2071 3.29289C21.3946 3.48043 21.5 3.73478 21.5 4V20C21.5 20.2652 21.3946 20.5196 21.2071 20.7071C21.0196 20.8946 20.7652 21 20.5 21H4.5C4.23478 21 3.98043 20.8946 3.79289 20.7071C3.60536 20.5196 3.5 20.2652 3.5 20V4C3.5 3.73478 3.60536 3.48043 3.79289 3.29289C3.98043 3.10536 4.23478 3 4.5 3ZM5.5 5V19H19.5V5H5.5ZM11.503 16L7.26 11.757L8.674 10.343L11.503 13.172L17.159 7.515L18.574 8.929L11.503 16Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_5652_1197">\n    <rect width="24" height="24" fill="white" transform="translate(0.5)"/>\n    </clipPath>\n    </defs>\n    </svg>',
                name: "agree",
                text: "Checkbox",
                type: "checkbox",
                label: "The store is registered business",
                style:
                  "font-weight: normal; font-size: 12px; font-family: sans-serif; color: #4B5563; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
                heading: null,
                styling: "inline",
                fieldStyle:
                  "font-size: 18px; font-family: sans-serif; color: #4B5563; font-weight: 600",
                parentStyle: null,
             
              },
              {
                id: 3,
                icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_103)">\n    <path d="M5 5V19H19V5H5ZM4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM9.869 15L9.049 17H6.833L11 7H13L17.167 17H14.95L14.13 15H9.87H9.869ZM10.689 13H13.311L12 9.8L10.689 13Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_103">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
                text: "Text Input",
                type: "text",
                label: null,
                style:
                  "font-weight: normal; font-size: 14px; font-family: sans-serif; color: #4B5563; outline: none; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
                styling: "global",
                required: false,
                inputType: "text",
                fieldStyle:
                  "font-size: 14px; background-color: #ffffff; color: #6b7280; padding: 12px 16px; border-radius: 8px; border: 1px solid #cbd5e0; outline: none",
                focusStyle: "border: 1px solid #2563EB; outline: none;",
                parentStyle: null,
                placeholder: "Company Legal Name",
             
              },
              {
                id: 3,
                icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_103)">\n    <path d="M5 5V19H19V5H5ZM4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM9.869 15L9.049 17H6.833L11 7H13L17.167 17H14.95L14.13 15H9.87H9.869ZM10.689 13H13.311L12 9.8L10.689 13Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_103">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
                text: "Text Input",
                type: "text",
                label: null,
                style:
                  "font-weight: normal; font-size: 14px; font-family: sans-serif; color: #4B5563; outline: none; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
                styling: "inline",
                required: false,
                inputType: "text",
                fieldStyle:
                  "font-size: 14px; background-color: #ffffff; color: #6b7280; padding: 12px 16px; border-radius: 8px; border: 1px solid #cbd5e0; outline: none",
                focusStyle: "border: 1px solid #2563EB; outline: none;",
                parentStyle: null,
                placeholder: "VAT EU Number",
              
              },
              {
                id: 3,
                icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_103)">\n    <path d="M5 5V19H19V5H5ZM4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM9.869 15L9.049 17H6.833L11 7H13L17.167 17H14.95L14.13 15H9.87H9.869ZM10.689 13H13.311L12 9.8L10.689 13Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_103">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
                text: "Text Input",
                type: "text",
                label: null,
                style:
                  "font-weight: bold; font-size: 14px; font-family: sans-serif; color: #65a30d; outline: none; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
                styling: "global",
                required: false,
                inputType: "text",
                fieldStyle:
                  "font-size: 14px; background-color: #ffffff; color: #6b7280; padding: 12px 16px; border-radius: 8px; border: 1px solid #cbd5e0; outline: none",
                focusStyle: "border: 1px solid #2563EB; outline: none;",
                parentStyle: null,
                placeholder: "Address",
                
              },
              {
                id: 3,
                icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_103)">\n    <path d="M5 5V19H19V5H5ZM4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM9.869 15L9.049 17H6.833L11 7H13L17.167 17H14.95L14.13 15H9.87H9.869ZM10.689 13H13.311L12 9.8L10.689 13Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_103">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
                text: "Text Input",
                type: "text",
                label: null,
                style:
                  "font-weight: normal; font-size: 14px; font-family: sans-serif; color: #4B5563; outline: none; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
                styling: "inline",
                required: false,
                inputType: "email",
                fieldStyle:
                  "font-size: 14px; background-color: #ffffff; color: #6b7280; padding: 12px 16px; border-radius: 8px; border: 1px solid #cbd5e0; outline: none",
                focusStyle: "border: 1px solid #2563EB; outline: none;",
                parentStyle: null,
                placeholder: "Email Address",
                
              },
              {
                id: 3,
                icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_103)">\n    <path d="M5 5V19H19V5H5ZM4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM9.869 15L9.049 17H6.833L11 7H13L17.167 17H14.95L14.13 15H9.87H9.869ZM10.689 13H13.311L12 9.8L10.689 13Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_103">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
                text: "Text Input",
                type: "text",
                label: null,
                style:
                  "font-weight: normal; font-size: 14px; font-family: sans-serif; color: #4B5563; outline: none; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
                styling: "global",
                required: false,
                inputType: "text",
                fieldStyle:
                  "font-size: 14px; background-color: #ffffff; color: #6b7280; padding: 12px 16px; border-radius: 8px; border: 1px solid #cbd5e0; outline: none",
                focusStyle: "border: 1px solid #2563EB; outline: none;",
                parentStyle: null,
                placeholder: "Phone No",
              
              },
              {
                id: 4,
                icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n      <g clip-path="url(#clip0_16_174)">\n      <path d="M12 13.172L16.95 8.22198L18.364 9.63598L12 16L5.63599 9.63598L7.04999 8.22198L12 13.172Z" fill="currentColor"/>\n      </g>\n      <defs>\n      <clipPath id="clip0_16_174">\n      <rect width="24" height="24" fill="currentColor"/>\n      </clipPath>\n      </defs>\n      </svg>',
                text: "Select",
                type: "select",
                label: null,
                style:
                  "font-weight: normal; font-size: 14px; font-family: sans-serif; color: #4B5563; padding-top: 0px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px",
                options: [
                  {
                    label: "Country",
                    value: "option1",
                  },
                  {
                    label: "Option 2",
                    value: "option2",
                  },
                ],
                styling: "global",
                multiple: false,
                required: false,
                fieldStyle:
                  "padding: 12px 16px; border: 1px solid #d4d4d4; border-radius: 6px; background-color: transparent",
                parentStyle: null,
                placeholder: "Start writing here...",
               
              },
              {
                id: 10,
                url: "/",
                icon: '<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_5652_1154)">\n    <path d="M20.106 6.995C20.03 6.697 19.814 6.472 19.567 6.403C19.13 6.28 17 6 12.5 6C8 6 5.872 6.28 5.431 6.403C5.187 6.471 4.971 6.696 4.894 6.995C4.785 7.419 4.5 9.196 4.5 12C4.5 14.804 4.785 16.58 4.894 17.006C4.97 17.303 5.186 17.528 5.432 17.596C5.872 17.72 8 18 12.5 18C17 18 19.129 17.72 19.569 17.597C19.813 17.529 20.029 17.304 20.106 17.005C20.215 16.581 20.5 14.8 20.5 12C20.5 9.2 20.215 7.42 20.106 6.995ZM22.043 6.498C22.5 8.28 22.5 12 22.5 12C22.5 12 22.5 15.72 22.043 17.502C21.789 18.487 21.046 19.262 20.105 19.524C18.396 20 12.5 20 12.5 20C12.5 20 6.607 20 4.895 19.524C3.95 19.258 3.208 18.484 2.957 17.502C2.5 15.72 2.5 12 2.5 12C2.5 12 2.5 8.28 2.957 6.498C3.211 5.513 3.954 4.738 4.895 4.476C6.607 4 12.5 4 12.5 4C12.5 4 18.396 4 20.105 4.476C21.05 4.742 21.792 5.516 22.043 6.498Z" fill="currentColor"/>\n    <path d="M13.5 11V8H11.5V11H8.5V13H11.499L11.5 16H13.5L13.499 13H16.5V11H13.5Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_5652_1154">\n    <rect width="24" height="24" fill="white" transform="translate(0.5)"/>\n    </clipPath>\n    </defs>\n    </svg>',
                text: "Button",
                type: "button",
                label: "Confirm Registration",
                style:
                  "font-weight: bold; font-size: 14px; font-family: sans-serif; cursor: pointer; background: #2563eb; color: white; border-radius: 8px; width: 100%; border: 1px solid #2563eb; text-align: center; align-self: start; padding-top: 8px; padding-bottom: 8px; padding-left: 16px; padding-right: 16px; box-shadow: none;",
                styling: "global",
              },
            ],
            formBody: {
              gap: "12px",
              "max-height": "100%",
              "min-height": "80px",
              "padding-top": "16px",
              "padding-left": "16px",
              "padding-right": "16px",
              "padding-bottom": "16px",
            },
           
          },
          {
            id: 9,
            src: "https://images.unsplash.com/photo-1728997150636-91ef00ba7e59?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHwxMnx8fGVufDB8fHx8fA%3D%3D",
            icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <g clip-path="url(#clip0_16_281)">\n    <path d="M4.828 21L4.808 21.02L4.787 21H2.992C2.72881 20.9997 2.4765 20.895 2.29049 20.7088C2.10448 20.5226 2 20.2702 2 20.007V3.993C2.00183 3.73038 2.1069 3.47902 2.29251 3.29322C2.47813 3.10742 2.72938 3.00209 2.992 3H21.008C21.556 3 22 3.445 22 3.993V20.007C21.9982 20.2696 21.8931 20.521 21.7075 20.7068C21.5219 20.8926 21.2706 20.9979 21.008 21H4.828ZM20 15V5H4V19L14 9L20 15ZM20 17.828L14 11.828L6.828 19H20V17.828ZM8 11C7.46957 11 6.96086 10.7893 6.58579 10.4142C6.21071 10.0391 6 9.53043 6 9C6 8.46957 6.21071 7.96086 6.58579 7.58579C6.96086 7.21071 7.46957 7 8 7C8.53043 7 9.03914 7.21071 9.41421 7.58579C9.78929 7.96086 10 8.46957 10 9C10 9.53043 9.78929 10.0391 9.41421 10.4142C9.03914 10.7893 8.53043 11 8 11Z" fill="currentColor"/>\n    </g>\n    <defs>\n    <clipPath id="clip0_16_281">\n    <rect width="24" height="24" fill="currentColor"/>\n    </clipPath>\n    </defs>\n    </svg>',
            text: "Image",
            type: "image",
            label: "image",
            style: "width: 512px; height: 810px; object-fit: cover",
            styling: "global",
         
          },
        ],
        formBody: {
          gap: "12px",
          "max-height": "100%",
          "min-height": "80px",
          "padding-top": "16px",
          "padding-left": "16px",
          "padding-right": "16px",
          "padding-bottom": "16px",
        },
        
      },
    ],
    backgroundColor: "#fffff",
    updatedDate: "2024-10-22T14:10:43.756000Z",
    created_at: "2024-05-16T11:53:26.000000Z",
    updated_at: "2024-10-22T14:10:44.000000Z",
    templateName: "Untitled Form"
  },
]);
