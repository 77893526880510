<template>
  <label
    class="switch"
    :class="disabled ? 'cursor-not-allowed pointer-events-none opacity-50' : ''">
    <input type="checkbox" @change="triggerToggle($event)" :checked="value" />
    <span class="slider round"></span>
  </label>
</template>

<script setup>
const emit = defineEmits(["update:modelValue"]);
// Props
const props = defineProps({
  value: [String, Number, Boolean],
  disabled: {
    type: Boolean,
    default: false,
  }
});
// Methods
const triggerToggle = (e) => {
  emit("update:modelValue", e.target.checked);
  emit("checked", e.target.checked);
  // emit("onChange", e.target.checked);
};
</script>
