import { defineStore } from "pinia";
import FileUploader from "~~/utils/fileUploader";
import { useAuthStore } from "./auth";
import { useMediaStore } from "./media";
import { STORAGE_LIMIT } from "./auth";
import axios from "axios";

export const useUploadStore = defineStore({
  id: "upload-store",
  state: () => {
    return {
      queue: [],
      queueStatus: [],
      uploadedList: [],
      isMediaUpdated: false,
      allUploaded: false,
      type: "type",
    };
  },
  actions: {
    clear() {
      this.queue = this.pendingFiles(this.queue);
      if (this.queue > 0) {
      } else {
        this.queue = [];
      }
    },
    storageLimitGB() {
      const authStore = useAuthStore();
      return STORAGE_LIMIT?.[authStore?.user?.role] ?? 0;
    },
    isUploadable(fsize) {
      const mediaStore = useMediaStore();
      // const storageLimit = 0.5;
      const storageLimit = this.storageLimitGB();
      const used = mediaStore.totalMediaSize(1);
      const storageLeft = storageLimit - used;
      const filesize = parseFloat(fsize / 1024 / 1024 / 1024);
      if (storageLimit !== 0) {
        if (storageLimit === -1) {
          return true;
        } else if (used > storageLimit || filesize > storageLeft) {
          return false;
        } else {
          return true;
        }
      }
    },
    isUploading(queue) {
      return queue.filter((file) => file.isUploading).length != 0;
    },
    pendingFiles(queue) {
      return queue.filter(
        (file) =>
          !file.isUploaded &&
          !file?.isError &&
          !file.isCancelled &&
          file.isReady
      );
    },
    nextFile(queue) {
      const files = this.pendingFiles(queue);
      if (files.length == 0) {
        return null;
      }
      this.allUploaded = false;
      this.uploadedList = files;
      return files[0];
    },
    deleteFile(id) {
      const file = this.queue.filter((item) => item.uuid == id);
      const filteredfiles = this.queue.filter((item) => item.uuid != id);
      if (file[0].isUploading) {
        file[0].api.cancel("Operation cancelled by the user.");
        this.queue = filteredfiles;
        file[0].isCancelled = true;
      } else {
        this.queue = filteredfiles;
      }

      return;
    },
    uploadNext() {
      const file = this.nextFile(this.queue);

      if (!file) {
        return;
      }
      const authStore = useAuthStore();
      const mediaStore = useMediaStore();

      // return;
      this.queueStatus[file.uuid] = {
        progress: 0,
        speed: 0,
        remaining: 0,
        uploaded: false,
      };
      const queueStatus = this.queueStatus[file.uuid];

      file.isUploading = true;
      const fileUploader = new FileUploader();
      file.api = axios.CancelToken.source();

      fileUploader
        .upload(
          useCookie("token"),
          file.api.token,
          file,
          {
            userId: authStore.getUser.user_uid,
          },
          0,
          0,
          ({ progress, speed, remaining }) => {
            queueStatus.progress = progress;
            queueStatus.speed = speed;
            queueStatus.remaining = remaining;
          }
        )
        .then((response) => {
          file.isUploaded = true;
          queueStatus.fileID = response.uniqueID;
          queueStatus.fileType = response.type;
          this.type = response.type;
          queueStatus.id = response.id;
          queueStatus.uploaded = true;
          // Listing files uses the last upload time to check whether the cache is stale.
          mediaStore.recordUpload();
        })
        .catch((error) => {
          console.error(error, "err");
          file.isError = true;
        })
        .finally(() => {
          this.isMediaUpdated = true;
          file.isUploading = false;

          if (this.nextFile(this.queue)) {
            this.allUploaded = false;
            this.uploadNext();
          } else {
            this.allUploaded = true;
            this.uploadedList = [];
          }
        });
      return;
    },
  },
  getters: {
    queueList: (state) => state.queue,
    queueStatusList: (state) => state.queueStatus,
    checkStatus: (state) => state.isMediaUpdated,
    checkAllUploaded: (state) => state.allUploaded,
    getMediaType: (state) => state.type,
    getUploadedMedia: (state) => state.uploadedList,
  },
});

export const total_storage = 10 * 1024 * 1024 * 1024; // 10GB
