<template>
  <div
    class="w-full relative cursor-default"
    :class="[
      fullWidth === 'true' ? '' : 'lg:max-w-[396px]',
      disabled ? 'cursor-not-allowed ' : '',
    ]"
    v-outside
  >
    <div
      class="flex flex-col w-full"
      :class="[
        fullWidth === 'true' ? 'w-full' : width ? width : 'md:max-w-[396px]',
        label ? 'gap-3' : '',
      ]"
      ref="dropdown"
    >
      <div class="flex items-center gap-3">
        <p
          class="font-semibold text-sm text-neutral-800 dark:text-white"
          :class="textClass ? textClass : 'leading-none'"
        >
          {{ label }}
          <span v-if="required" class="text-red-700"> * </span>
          <span class="text-neutral-500" v-if="labelLight">
            {{ labelLight }}
          </span>
        </p>
        <svg
          v-if="toolTipText"
          class="text-neutral-800 dark:text-neutral-200 cursor-pointer"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.5625 6.5625L6.5867 6.5504C6.92102 6.38324 7.29745 6.6852 7.2068 7.04782L6.7932 8.70218C6.70255 9.0648 7.07898 9.36676 7.4133 9.1996L7.4375 9.1875M12.25 7C12.25 9.89949 9.89949 12.25 7 12.25C4.10051 12.25 1.75 9.89949 1.75 7C1.75 4.10051 4.10051 1.75 7 1.75C9.89949 1.75 12.25 4.10051 12.25 7ZM7 4.8125H7.00438V4.81688H7V4.8125Z"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <!-- @click="showDropdown = !showDropdown" -->
      <!-- @click="(clicked = true), (showDropdown = !showDropdown)" -->
      <div class="flex items-center bg-transparent">
        <div
          class="inline-flex w-full items-center justify-between gap-x-1.5 rounded-md bg-white text-sm font-semibold text-neutral-600 border dark:bg-neutral-800 outline-none transition-all ease-in-out duration-300 pl-px py-px cursor-pointer"
          @click="handleShowDropdown"
          :class="[
            error
              ? '!border-red-500'
              : highlight
              ? 'border-blue-500'
              : moreClass
              ? moreClass
              : disabled
              ? 'cursor-not-allowed bg-zinc-100 text-zinc-400'
              : 'border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 cursor-pointer',
            customPadding ? customPadding : ' px-3 py-[12px] ',
            clicked ? 'border-blue-600' : '',
            highlight == true
              ? 'border-blue-500 shadow-md shadow-blue-500/30'
              : 'border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 shadow-none',
            disabled ? 'pr-[1px]' : 'pr-3',
          ]"
        >
          <input
            :disabled="disabled"
            :required="required ?? false"
            :placeholder="optionlabel ?? 'Select an option'"
            @keyup="filterOptions"
            autocomplete="off"
            class="leading-none dark:text-neutral-400 inline-flex w-full items-center justify-between gap-x-1.5 rounded-md bg-white text-sm font-normal text-neutral-600 dark:bg-neutral-800 placeholder:text-neutral-400 outline-none transition-all ease-in-out duration-150"
            v-model="dropDownText"
            :class="[
              error
                ? '!border-red-500'
                : highlight
                ? 'border-blue-500'
                : moreClass
                ? moreClass
                : disabled
                ? 'cursor-not-allowed bg-zinc-100 text-zinc-400'
                : 'border-neutral-300 dark:border-neutral-700 cursor-pointer',
              customPadding ? customPadding : ' px-3 py-[12px] ',
              clicked ? 'border-blue-600' : '',
            ]"
            :dusk="label?.split(' ')?.[0] ?? ''"
            :id="id || 'search-dropdown-input'"
          />
          <div v-if="dropDownText" @click.stop="onDropdownClick('')">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4 text-neutral-600 dark:text-neutral-400 cursor-pointer"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </div>
          <svg
            v-if="!disabled"
            width="18"
            class="text-neutral-600 dark:text-neutral-400 transition-all ease-out duration-300 cursor-pointer"
            :class="showDropdown ? 'rotate-180 ' : ''"
            height="18"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.25 6.875L10 13.125L3.75 6.875"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
    <div
      v-if="showDropdown"
      class="z-[9] bg-white border dark:border-neutral-700 rounded-lg w-full dark:bg-neutral-800 mt-2 absolute h-fit max-h-[200px] truncate !overflow-y-auto content-scroll"
      :class="
        (disabled && 'hidden',
        showDropdownPosition == 'top' ? 'bottom-[54px]' : '')
      "
    >
      <ul class="py-1 text-sm text-gray-700 dark:text-gray-200">
        <li
          v-if="
            filterClear ||
            filteredData == null ||
            Object.keys(filteredData).length == 0
          "
          @click.prevent="
            [(showDropdown = false), filterClear && onDropdownClick('')]
          "
          class="px-4 py-3 font-normal text-sm text-neutral-600 dark:text-neutral-200 hover:bg-blue-50 dark:hover:bg-blue-400/10 hover:text-blue-600 dark:hover:text-blue-400 cursor-pointer"
          :class="
            filterList
              ? 'border-b border-neutral-300 dark:border-neutral-700'
              : ''
          "
        >
          {{ filterClear ? "None" : "No options available" }}
        </li>
        <p
          v-for="item in sortOptions()"
          :title="item.title"
          ref="dropdownItems"
          :key="item.value"
          @click.prevent="onDropdownClick(item)"
          class="px-4 py-3 flex items-center gap-1 font-normal text-sm text-neutral-600 dark:text-neutral-200 hover:bg-blue-50 dark:hover:bg-blue-400/10 hover:text-blue-600 dark:hover:text-blue-400 cursor-pointer"
          :class="
            item.value === value &&
            ' bg-blue-50 dark:bg-blue-400/10 !text-blue-600 dark:!text-blue-400'
          "
          :dusk="item.title"
          :data-value="item.value"
        >
          <span v-if="imageIcon && item.type">
            <img
              :src="`${cdnURL}${item.type.toLowerCase()}.png`"
              alt=""
              class="w-5 h-5 rounded-full"
            />
          </span>
          <span class="truncate">
            {{ item.title }}
          </span>
        </p>
      </ul>
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits(["change", "update:modelValue"]);
const clicked = ref(false);
const dropdownItems = ref([]);
// Props
const props = defineProps({
  label: String,
  toolTipText: String,
  options: { type: Object, default: {} },
  labelLight: String,
  fullWidth: String,
  error: { type: Boolean, default: false },
  highlight: { type: Boolean, default: false },
  moreClass: String,
  textClass: String,
  customPadding: String,
  width: String,
  modalvalue: String,
  filterClear: String,
  filterList: String,
  imageIcon: Boolean,
  required: Boolean,
  optionlabel: { type: String, default: "Select an option" },
  disabled: {
    type: Boolean,
    default: false,
  },
  value: { type: [String, Number, Array], default: null },
  fit: {
    type: Boolean,
    default: false,
  },
  id:String,
});
const showDropdown = ref(false);
const showDropdownPosition = ref("bottom");
let dropdown = ref(null);
const config = useRuntimeConfig();
const cdnURL = config.public.cdnURL;

// Refs
let selectedOption = ref(null);
const filteredData = ref(props.options ? props.options : null);
const dropDownText = ref(
  props?.value
    ? props?.options
      ? props?.options[props.value]?.title
      : null
    : null
);
// Methods
watch(showDropdown, (newValue) => {
  if (newValue) {
    nextTick(() => {
      scrollToSelectedItem();
    });
  }
});

const scrollToSelectedItem = () => {
  // Find the selected item in the list
  const selectedItem = dropdownItems.value.find(
    (el) => el?.dataset?.value === props?.value?.toString()
  );

  if (selectedItem) {
    // Scroll the selected item into view
    selectedItem.scrollIntoView({
      block: "nearest",
    });
  }
};

const loadSettings = () => {
  const val = props?.value;
  if (val != null && props.options != null) {
    selectedOption.value =
      filteredData.value[val] != undefined && filteredData.value[val] != null
        ? filteredData.value[val]
        : {};
    dropDownText.value = selectedOption.value.title;
    emit("update:modelValue", selectedOption.value.value || null);
  }
};
const handleShowDropdown = () => {
  clicked.value = true;
  showDropdown.value = !showDropdown.value;

  if (dropdown?.value) {
    let temp = dropdown.value.getBoundingClientRect().bottom;
    let spaceBelow = window.innerHeight - temp;
    showDropdownPosition.value = spaceBelow < 250 ? "top" : "bottom";
  }
};
const onDropdownClick = (item) => {
  if (item == "none") {
    selectedOption.value = null;
    dropDownText.value = null;
  } else {
    selectedOption.value = item;
    dropDownText.value = item.title;
    filteredData.value = props.options;

    emit("update:modelValue", item.value);
    emit("change", item.value, item.title);
    showDropdown.value = false;
  }
};
watch(
  () => props.options,
  () => {
    filteredData.value = props.options;
    loadSettings();
  },
  { immediate: true }
);

watch(
  () => props.value,
  () => {
    loadSettings();
  }
);

const filterOptions = (e) => {
  let filterTerm = e.target.value;
  let filteredObject = {};
  Object.entries(props.options).filter(([key, value]) => {
    if (value.title.toLowerCase().includes(filterTerm.toLowerCase())) {
      filteredObject[key] = value;
    }
  });
  filteredData.value = filteredObject;
};
const sortOptions = () => {
  let data = filteredData.value;
  // Extract titles and values into an array of objects
  const dataArray = Object.values(data);
  // Sort the array by title alphabetically
  dataArray?.sort((a, b) => a.title?.localeCompare(b.title));

  return dataArray;
};

const vOutside = {
  mounted(el, binding, vnode) {
    const onClickOutside = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        showDropdown.value = false;
      }
    };
    setTimeout(() => {
      document.body.addEventListener("click", onClickOutside);
      el._onClickOutside = onClickOutside;
    }, 2000);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el._onClickOutside);
    delete el._onClickOutside;
  },
};
</script>
