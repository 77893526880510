import { timezones } from "./data";
// import { useAuthStore } from "~~/store/auth";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import ct from "countries-and-timezones";
import pako from "pako";

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const getDateTimeString = (pString, pStyle, user) => {
  pStyle = pStyle || "";

  if (user && user.timezone) {
    let timezoneOffset = timezones()[user.timezone];
    let timezoneName = user.timezone;

    let sOffset = parseInt(timezoneOffset);
    if (!sOffset) sOffset = parseInt("0");

    sOffset = sOffset;
    var timestamp;
    if (isNaN(pString)) timestamp = Date.parse(pString) + sOffset * 1000 * 3600;
    else timestamp = pString * 1000;
    var date = new Date(timestamp); //.toString("hh:mm")

    var invdate = new Date(
      date.toLocaleString("en-US", {
        timeZone: timezoneName,
      })
    );
    date = invdate;
    var hours = date.getHours();

    var dd = date.getDate();
    var mm = date.getMonth();
    var yyyy = date.getFullYear();
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    if (dd < 10) {
      dd = dd;
    }
    if (mm < 10) {
      mm = mm;
    }
    var dateStr = months[mm] + " " + dd + ", " + yyyy;
    var hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    var am_pm = date.getHours() >= 12 ? "PM" : "AM";
    var minutes =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    if (hours == 0 && am_pm == "AM") hours = 12;
    if (hours == 0) hours = "00";
    if (minutes == 0) minutes = "00";
    var time = hours + ":" + minutes + " " + am_pm;
    if (pStyle == "time") return time;
    return dateStr; // + " " + time;
  }
};

export const getDateTimeStringWebinar = (date, format, user) => {
  // const hours = utcAdjust(date);

  const tz = user.timezone;
  var timestamp;
  if (isNaN(date)) timestamp = Date.parse(date);
  else timestamp = date * 1000;
  if (format == "time") {
    return dayjs(timestamp).format("h:mm A z", { timeZone: tz });
  } else {
    return dayjs(timestamp).format("MMM DD, YYYY");
  }
};

export const getDateTimeStringNew = (date, format, user) => {
  return dayjs.utc(date).format(format);
};

export const formatCalendarDate = (date) => {
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

export const asPercent = (value) => {
  const floating = parseFloat(value);
  if (isNaN(floating)) return "0%";
  return `${floating?.toFixed(1)}%`;
};

export const getPercentage = (value, total) => {
  const percent = ((parseInt(value) / parseInt(total)) * 100).toFixed(0);
  return Number.isNaN(percent) || percent === "NaN" ? 0 : percent;
};

export const getMediaDate = (str) => {
  let d = new Date(str);
  let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
  let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
  const date = `${mo} ${da}, ${ye}`;

  return date;
};

export const getMediaLength = (str) => {
  const all = str.split(":");
  const hours = all[0];
  const minutes = all[1];
  const seconds = all[2];
  if (
    typeof hours === "undefined" ||
    typeof minutes === "undefined" ||
    typeof seconds === "undefined"
  ) {
    return "Calculating";
  }
  const length =
    hours !== "00" && minutes !== "00" && seconds !== "00"
      ? `${hours} hours, ${minutes} mins & ${seconds} sec`
      : hours !== "00" && seconds !== "00"
      ? `${hours} hours & ${seconds} sec`
      : minutes !== "00" && seconds !== "00"
      ? `${minutes} mins & ${seconds} sec`
      : seconds !== "00"
      ? `${seconds} sec`
      : hours !== "00"
      ? `${hours} hours`
      : minutes !== "00"
      ? `${minutes} mins`
      : "";
  return length;
};

export const getTimestamp = (strDate) => {
  let datum = Date.parse(strDate);
  return datum / 1000;
};

export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export const formatFileSizeHelper = (byte) => {
  if (byte < 1024) {
    return `${byte}B`;
  }
  if (byte < 1024 * 1024) {
    return `${Math.floor(byte / 1024)}KB`;
  }
  if (byte < 1024 * 1024 * 1024) {
    return `${Math.floor(byte / 1024 / 1024)}MB`;
  }
  return `${(byte / 1024 / 1024 / 1024).toFixed(2)}GB`;
};

export const removeShortCodes = (text) => {
  return text
    .replaceAll(/\{(:?.*?)\}+/g, "")
    .replaceAll(/(?:%7B)(?:.*?)(:?%7D)+/g, "");
};

export const validateEmail = (email) => {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(email);
};
export const validateNewPassword = (password) => {
  return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/.test(
    password
  );
};
// export const validateUrl = (url) => {
//   return /^((http|https):\/\/)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$/.test(
//     url
//   );
// };
export const validateUrl = (url) => {
  return /^((http|https):\/\/)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%._\\+~#?&//=]*({{![^{}]+!}})?)*$/.test(
    url
  );
};

export const validatePersonalizedUrl = (url) => {
  return validateUrl(removeShortCodes(url));
};
// export const validateUrlHttps = (url) => {
//   return /^(https:\/\/)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$/.test(
//     url
//   );
// };

export const validateUrlHttps = (url) => {
  const httpsCount = url.match(/https?:\/\//g) || []?.length;
  const cleanUrl = url.replace(/[{}!-]/g, ""); // Remove {, }, !, or -
  if (httpsCount?.length !== 1) {
    return false;
  } else {
    return /^((http|https):\/\/)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$/.test(
      cleanUrl
    );
  }

  // return /^(https?:\/\/)[-a-zA-Z0-9@:%._\\+~#?&//=]*\.[a-z]{2,}(\/)?$/i.test(cleanUrl);
};
export const formatHours = (hours) => {
  if (hours.toString().length <= 1 && hours.toString() != "0") {
    hours = "0" + hours;
  } else if (hours.toString().length >= 3) {
    hours = Number(hours);
  }
  if (hours > 12 || hours <= 0) {
    if (hours <= 0) {
      hours = 0;
    }
    if (hours > 12) {
      hours = 12;
    }
  }
  return hours;
};

export const formatMinutes = (minutes) => {
  if (minutes.toString().length <= 1 && minutes.toString() != "0") {
    minutes = "0" + minutes;
  } else if (minutes.toString().length >= 3) {
    minutes = Number(minutes);
  }
  if (minutes > 59 || minutes < 0) {
    if (minutes <= 0) {
      minutes = 0;
    }
    if (minutes > 59) {
      minutes = 59;
    }
    if (minutes !== minutes) {
      minutes = minutes;
    }
    if (typeof minutes !== "number") {
      return;
    }
  }
  return minutes;
};

export const downloadFile = async (token, url, filename = "download.csv") => {
  try {
    const response = await fetch(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (!response.ok) {
      throw new Error(
        `Failed to fetch: ${response.status} ${response.statusText}`
      );
    }

    const blob = await response.blob();

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;

    // Trigger a click event on the link to initiate the download
    link.click();

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const retryDownloadFile = async (
  token,
  url,
  filename = "download.csv",
  maxTries = 10
) => {
  let tries = 0;
  let success = false;

  while (!success && tries < maxTries) {
    success = await downloadFile(token, url, filename);
    tries++;
  }
};

export const formatSelectData = (obj) => {
  let formattedObj = {};

  Object.keys(obj).map((key, idx) => {
    formattedObj[key] = {
      title: obj[key].title ? obj[key].title : obj[key],
      value: key,
    };
  });
  return formattedObj;
};

// Formatter for [TimeLine > Integrations] Data
export const formatTLIntegrationData = (obj, type, isUUID) => {
  if (!obj) return {};
  let formattedObj = {};
  if (type == "options") {
    Object.keys(obj ?? {}).map((item, idx) => {
      formattedObj[item] = {
        title: obj[item].name ? obj[item].name : obj[item].title,
        value: obj[item].id,
      };
    });
  } else if (type == "options2") {
    Object.keys(obj ?? {}).map((item, idx) => {
      formattedObj[item] = {
        title: obj[item].name,
        value: item.id,
      };
    });
  } else if (type == "optionsGetResponse") {
    Object.keys(obj ?? {}).map((item, idx) => {
      formattedObj[item] = {
        title: obj[item].name,
        value: item,
      };
    });
  } else if (type == "webhooks") {
    Object.keys(obj ?? {}).map((item, idx) => {
      formattedObj[item] = {
        title: obj[item].label,
        value: obj[item].webhook,
      };
    });
  } else if (type == "optionsWithBothNames") {
    Object.keys(obj ?? {}).map((item, idx) => {
      formattedObj[item] = {
        title: obj[item].name,
        value: obj[item].name,
      };
    });
  } else if (type == "media") {
    if (isUUID == undefined) {
      isUUID = false;
    }
    Object.values(obj ?? {}).map((media) => {
      let key = "uniqueID";
      if (isUUID) {
        key = "uuid";
      }
      formattedObj[media[key]] = {
        title: media.name,
        value: media[key],
        folderName:media.folderName || "",
        folderID:media.folderID || "",
        thumbnail:media.thumbnail || "",
        video_length:media.video_length || "",
        upload_url:media.upload_url || "",
        type:media.type || "",
        transcoding:media.transcoding || 0,
        created_at:media.created_at || ""
      };
    });
  } else if (type == "nifty") {
    Object.values(obj ?? {}).map((media) => {
      formattedObj[media.Url] = {
        title: media.Name,
        value: media.Url,
      };
    });
  } else if (type == "tags") {
    Object.keys(obj ?? {}).map((item, idx) => {
      formattedObj[item] = {
        title: obj[item].name,
        value: obj[item].tagId,
      };
    });
  } else if (type == "tagsConvertKit") {
    Object.keys(obj ?? {}).map((item, idx) => {
      formattedObj[item] = {
        title: obj[item].name,
        value: obj[item].tagId,
      };
    });
  } else if (type == "tagsOntraport") {
    Object.keys(obj ?? {}).map((item, idx) => {
      formattedObj[item] = {
        title: obj[item].name,
        value: obj[item].tagId,
      };
    });
  } else if (type == "customFieldTypes") {
    Object.keys(obj ?? {}).map((item, idx) => {
      formattedObj[item] = {
        title: obj[item],
        value: item,
      };
    });
  } else if (type == "customForms") {
    Object.keys(obj ?? {}).map((item, idx) => {
      formattedObj[obj[item].name] = {
        title: obj[item].name,
        value: obj[item].name,
      };
    });
  } else if (type == "headers") {
    Object.keys(obj ?? {}).map((item, idx) => {
      formattedObj[obj[item]] = {
        title: obj[item],
        value: obj[item],
      };
    });
  } else if (type == "adAccounts") {
    Object.values(obj).map((item, idx) => {
      formattedObj[item.id] = {
        title: item.name,
        value: item.id,
      };
    });
  }
  return formattedObj;
};

export const autotimeTypeData = {
  "webinar-time": { title: "Webinar Start Time", value: "webinar-time" },
  "webinar-time-reg": {
    title: "Webinar Start Time (Registrant Timezone)",
    value: "webinar-time-reg",
  },
  "registration-time": {
    title: "Registration Time",
    value: "registration-time",
  },
  "registration-time-reg": {
    title: "Registration Time (Registrant Timezone)",
    value: "registration-time-reg",
  },
  "replay-time": { title: "Replay Expiration", value: "replay-time" },
  "replay-time-reg": {
    title: "Replay Expiration (Registrant Timezone)",
    value: "replay-time-reg",
  },
  "custom-field": { title: "Custom Field", value: "custom-field" },
  "edit-text": { title: "Custom Value", value: "edit-text" },
  url: { title: "Registrant Info", value: "url" },
};
export const urlData = {
  uuid: { title: "Registrant ID", value: "uuid" },
  webinarID: { title: "Webinar ID", value: "webinarID" },
  "join-url": { title: "Join URL", value: "join-url" },
  "replay-url": { title: "Replay URL", value: "replay-url" },
  email: { title: "Email", value: "email" },
  firstName: { title: "First Name", value: "firstName" },
  lastName: { title: "Last Name", value: "lastName" },
  phone: { title: "Phone", value: "phone" },
  platformURL: { title: "Platform Join URL", value: "platformURL" },
  country: { title: "Country", value: "country" },
  ipAddress: { title: "IP Address", value: "ipAddress" },
  "timeZone-reg": { title: "Time Zone", value: "timeZone-reg" },
  webinarTitle: { title: "Webinar Subject", value: "webinarTitle" },
  webinarBody: { title: "Webinar Description", value: "webinarBody" },
  apple_calendar: { title: "Add to Apple Calendar Link", value: "apple_calendar" },
  google_calendar: { title: "Add to Google Calendar Link", value: "google_calendar" },
  attendance: { title: "Attendance Time (seconds)", value: "attendance" },
};
export const checkIntegrationOption = (model, keyword) => {
  if (model[keyword]) {
    model[keyword] = false;
  } else {
    model[keyword] = true;
  }
};

export const popupCenter = ({ url, title, w, h }) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `
  );

  if (window.focus) newWindow.focus();
};

export const removeFromArrByID = (arr, id) => {
  const objWithIdIndex = arr.findIndex((obj) => obj.id === id);

  if (objWithIdIndex > -1) {
    arr.splice(objWithIdIndex, 1);
  }
  return arr;
};

export const decodeURIAll = (param) => {
  let str = param;
  try {
    while (str != decodeURI(str)) {
      str = decodeURI(str);
    }
  } catch (e) {
    return str ? str : "";
  }
  return str ? str : "";
};

export const deepCloneObject = (obj) => {
  try {
    return JSON.parse(JSON.stringify(obj));
  } catch (e) {
    return {};
  }
};

export const formatIntegrationsDropdown = (obj, isNifty = false) => {
  let formattedIntegraions = {};
  if (isNifty) {
    Object.values(obj).map((item, _idx) => {
      formattedIntegraions[item.Url] = {
        title: item.Name,
        value: item.Url,
      };
    });
  } else {
    Object.values(obj).map((item, _idx) => {
      formattedIntegraions[
        item.integrationid ? item.integrationid : item.uniqueID
      ] = {
        title: item.name,
        value: item.integrationid ? item.integrationid : item.uniqueID,
        type:
          item.type == "WEBINAR" || item.type == "AUTOMA8"
            ? "aevent"
            : item.type,
        folderName:item.folderName || "",
        folderID:item.folderID || "",
        thumbnail:item.thumbnail || "",
        video_length:item.video_length || "",
        upload_url:item.upload_url || "",
        media_type:item.type || "",   
        transcoding:item.transcoding || 0,
        created_at:item.created_at || ""
      };
    });
  }

  return formattedIntegraions;
};

export const formatIntegrationActionDropdown = (obj) => {
  let formattedIntegrationAction = {};
  Object.keys(obj ?? {}).map((action, _idx) => {
    formattedIntegrationAction[action] = {
      title: obj[action].title,
      value: action,
    };
  });
  // console
  return formattedIntegrationAction;
};

export const formatIntegrationsData = (obj) => {
  let formarttedIntegrationData = {};
  Object.values(obj).map((item, _idx) => {
    if (item) {
      const key =
        item.id != undefined
          ? item.id
          : item.tagId != undefined
          ? item.tagId
          : item.list_id != undefined
          ? item.list_id
          : item.listId;
      formarttedIntegrationData[key] = {
        title: item.name,
        value: key,
      };
    }
  });
  return formarttedIntegrationData;
};
// for type sendlane only
export const formatIntegrationsDataSendlane = (obj, list, fallback = null) => {
  let formarttedIntegrationData = {};
  Object.values(obj).map((item, _idx) => {
    if (item) {
      const key =
        item.id != undefined
          ? item.id
          : list
          ? "" + item.list_id
          : item.tag_name;
      formarttedIntegrationData[key] = {
        title: list ? item.list_name : key,
        value: key,
      };
    }
  });
  if (fallback && typeof formarttedIntegrationData[fallback] === "undefined") {
    formarttedIntegrationData[fallback] = { title: fallback, value: fallback };
  }
  return formarttedIntegrationData;
};

export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return;
  const finalSentence = string?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
    letter.toUpperCase()
  );
  return finalSentence;
};

export const actionIcons = (type) => {
  if (!type) return "";
  if (type == "click-bank") {
    return "dollar-sign-alt";
  } else if (
    (type.includes("mail") && !type.includes("voicemail")) ||
    type.includes("webhook") ||
    type.includes("bell-ring")
  ) {
    return "envelope";
  } else if (type.includes("tag")) {
    return "tag-alt";
  } else if (type.includes("message")) {
    return "comments-alt";
  } else if (type.includes("folder-remove")) {
    return "folder-times";
  } else if (type.includes("account-remove")) {
    return "user-times";
  } else if (type.includes("gesture-tap-button")) {
    return "expand-from-corner";
  } else if (type.includes("folder-move")) {
    return "folder-upload";
  } else if (type.includes("folder-clock")) {
    return "pause";
  } else if (type.includes("facebook-messenger")) {
    return "facebook-messenger-alt";
  } else if (type.includes("link-h")) {
    return "link-h";
  } else if (type.includes("folder-plus")) {
    return "folder-plus";
  } else if (type.includes("whatsapp")) {
    return "whatsapp";
  } else if (type.includes("voicemail")) {
    return "voicemail";
  } else {
    return "calendar-alt";
  }
};
export const convertToBinary = (input) => {
  let output = "";
  for (var i = 0; i < input.length; i++) {
    output += input[i].charCodeAt(0).toString(2) + " ";
  }
  return output;
};

export const getTimeString = (innerNode) => {
  const { key, days, hours, minutes, isExact, isAfter, amPm } = innerNode;
  let timeStr = "";
  // here is the exact time string
  if (isExact) {
    if (hours !== 0 && hours !== null) {
      timeStr += `${hours == null ? 12 : hours}:${
        minutes == null || minutes == "0"
          ? "00"
          : parseInt(minutes) < 10
          ? "0" + parseInt(minutes)
          : minutes
      } ${amPm == 1 ? "PM" : "AM"}`;
    } else {
      timeStr += `${hours == null ? 12 : hours}:${
        minutes == null || minutes == "0"
          ? "00"
          : parseInt(minutes) < 10
          ? "0" + parseInt(minutes)
          : minutes
      } ${amPm == 1 ? "PM" : "AM"}`;
    }
  } else {
    // here is relative timeString
    if (key != 0) {
      if (days != null && days != 0 && !isNaN(days)) {
        timeStr += `${Math.abs(days)} day${Math.abs(days) === 1 ? "" : "s"} `;
      }
    } else {
      timeStr += "";
    }

    if (hours != 0 && hours != null) {
      timeStr += `${hours} HR`;
    }

    if (minutes != 0 && minutes != null) {
      timeStr += `${hours != 0 || hours != null ? " " : ""}${minutes} min${
        minutes == 1 ? "" : "s"
      }`;
    }
    if (minutes == 0 && minutes != null) {
      timeStr += `${hours != 0 || hours != null ? " " : ""}0 min${
        minutes == 1 ? "" : "s"
      }`;
    }

    if (parseInt(isAfter) < 0) {
      timeStr += " before";
    } else if (parseInt(isAfter) > 0) {
      timeStr += " after";
    } else {
      timeStr += " ";
    }
  }
  return hours !== null || minutes !== null
    ? timeStr
    : ` 0min ${parseInt(isAfter) < 0 ? " before" : " after"} `;
};

export const getActionDescription = (integrationAction, model) => {
  // here we are getting the description of the integration action
  if (!integrationAction) return "";
  const action = integrationAction?.split("-");
  const checkAction = action[1] ? action[1] : action[0];
  switch (checkAction) {
    case "removeTag":
    case "tag-id":
    case "addTag":
      return model && model["tagName"]
        ? model["tagName"]
        : model["tag-name"]
        ? model["tag-name"]
        : "";
    case "addToCampaign":
    case "pauseCampaign":
    case "subscribeToCampaign":
    case "removeFromCampaign":
      return model && model["campaignName"] ? model["campaignName"] : "";
    case "addToList":
    case "removeFromList":
      return model && model["listName"] ? model["listName"] : "";
    case "sendSMS":
    case "sendsms":
    case "textuser":
      return model && model["sms-text"] ? model["sms-text"] : "";
    case "sendmms":
    case "sendMMS":
      return model && model["mms-text"] ? model["mms-text"] : "";
    case "sendText":
    case "sendMediaMsg":
      return model && model["text"] ? model["text"] : "";
    case "webhook":
      return model && model["address"] ? model["address"] : "";
    case "moveList":
      return model && model["toListName"]
        ? model["toListName"] + "&fromList" + model["listName"]
        : "";
    case "sendWhatsAppTemplate":
      return model && model["template"] ? model["template"] : "";
    case "livechat":
      return model && model["message"] ? model["message"] : "";
    case "addGoal":
      return model && model["goalName"] ? model["goalName"] : "";
    case "moveToList":
      return model && model["listName"] ? model["listName"] : "";
    case "sendrvm":
    case "vmmsg":
      return model && model["filename"] ? model["filename"] : "";
    case "sendWebhook":
      return model && model["webhook"] ? model["webhook"] : "";
    case "removeFromAutomation":
    case "addToAutomation":
      return model && model["automationName"] ? model["automationName"] : "";
    case "removeSubscriber":
      return model && model["client"]
        ? model["list"] + "&fromList" + model["client"]
        : "";
    case "manychatwhatsapp":
    case "sendWhatsappTemplate":
      return model && model["flowName"] ? model["flowName"] : "";
  }
};

export const getInsertDayKeyFromNode = (nodes) => {
  let insertKey = null;
  // new Number(nodes.key ? nodes.key : nodes.days);
  if (nodes.key) {
    insertKey = parseInt(nodes.key);
  } else {
    insertKey = parseInt(nodes.days);
    if (parseInt(nodes.isAfter) == -1) insertKey *= -1;
  }
  if (nodes.isExact) {
    if (parseInt(nodes.isAfter) == -1) {
      insertKey = insertKey - 1;
    }
  } else {
    if (parseInt(nodes.isAfter) == 1) {
    } else {
      if (nodes.nodeID == 1440) {
        nodes.nodeID = 0;
      } else {
        insertKey = insertKey - 1;
      }
    }
  }
  return insertKey;
};

export const getRefinedNode = (nodes) => {
  nodes["insertDayKey"] = getInsertDayKeyFromNode(nodes);
  return nodes;
};

export const getInsertDayKey = (insertDayKey) => {
  let insertKey = insertDayKey;
  if (insertKey > 0) insertKey = insertKey;
  // if (insertKey < 0) insertKey = insertKey +;
  return insertKey.toString();
};
export const removeNodeByID = (origin, id) => {
  let nodesGroup = JSON.parse(JSON.stringify(origin));
  Object.keys(nodesGroup).forEach((dayKey) => {
    const nodes = nodesGroup[dayKey];
    if (!nodes) return;
    else {
      nodesGroup[dayKey] = nodes.filter((node) => node.id !== id);
    }
  });
  return nodesGroup;
};
export const sortByNodeID = (origin) => {
  let nodes = JSON.parse(JSON.stringify(origin));
  nodes.sort((a, b) =>
    a.nodeID > b.nodeID ? 1 : b.nodeID > a.nodeID ? -1 : 0
  );
  return nodes;
};

export const getDayAndNodeID = (day, nodeID, offset) => {
  const oneDayMinutes = 24 * 60;
  let newDay = day;
  let newNodeID = nodeID + offset;
  if (newNodeID > oneDayMinutes) {
    newDay = parseInt(day) + 1;
    newNodeID -= oneDayMinutes;
  } else if (newNodeID < 0) {
    newDay = parseInt(day) - 1;
    newNodeID = oneDayMinutes + newNodeID;
  }
  if (newDay > 0) newDay = "+" + newDay;
  return {
    day: newDay.toString(),
    nodeID: newNodeID,
  };
};

export const getCountdownLength = (generalSetting) => {
  let length = 0;
  try {
    let countdownSetting = generalSetting.countdown;
    if (countdownSetting) {
      if (countdownSetting.enabled) {
        length += parseInt(countdownSetting.duration || 0);
      }
    }
  } catch (e) {}
  return length;
};

export const getWebinarVideoLength = (generalSetting, videos = []) => {
  let length = 0;
  try {
    if (generalSetting.webinarType == "manual") {
      if (generalSetting.countdown.enabled) {
        length = (generalSetting.countdown.manaulLength || 0) * 60;
        return length;
      }
    }
    length += getVideoLengthByID(generalSetting.videoFile, videos);
    let advancedVideo = generalSetting.advanced;
    if (advancedVideo.enabled == true) {
      length +=
        getVideoLengthByID(advancedVideo.appendIntro, videos) +
        getVideoLengthByID(advancedVideo.appendOutro, videos);
    }
  } catch (e) {}
  return length;
};

export const getWebinarLength = (generalSetting, videos = []) => {
  let length = 0;
  let customLength = 0;
  try {
    if (generalSetting.splitVideo.enabled == true) {
      generalSetting.splitVideo.videos.map((item) => {
        length += getVideoLengthByID(item, videos);
      });
    } else {
      length = getWebinarVideoLength(generalSetting, videos);
    }
    length = length / 60; // Convert to Minute
    if (generalSetting.webinarType == "semi-automatic") {
      if (generalSetting.manualControl.enabled) {
        customLength = parseInt(generalSetting.manualControl.duration || 0);
      }
    } else if (generalSetting.webinarType == "manual") {
      length = parseInt(generalSetting.manualLength || 0);
    }
  } catch (e) {}
  return Math.max(length + customLength, 0);
};

export const getTimeStringWithTimezone = (pTimestamp, user) => {
  let timezone = user.timezone;
  var timestamp = pTimestamp * 1000;
  var date = new Date(timestamp);

  var options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: timezone,
  };

  var formatter = new Intl.DateTimeFormat("en-US", options);
  var timeString = formatter.format(date);
  return timeString;
};
export const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};
export const getEndOfDay = (dateStr) => {
  const date = new Date(dateStr);
  date.setHours(23, 59, 59, 999); // Set end time to one second before midnight
  return date.toISOString();
};
export const clearForm = () => {
  return {
    isAfter: "-1",
    integrationID: null,
    groupID: null,
    isExact: true,
    amPm: "0",
    model: {
      isDelete: false,
      eventRelation: null,
      eventName: "",
    },
    hours: "12",
    minutes: "00",
    days: null,
    day: null,
    action: null,
    templateID: null,
  };
};

export const setTemplateKeys = (key) => {
  if (key == "live-webinar") {
    return "LIVE";
  } else if (key == "hybrid-webinar") {
    return "Hybrid";
  } else if (key == "evergreen-webinar") {
    return "Evergreen";
  } else if (key == "vsl") {
    return "vsl";
  } else {
    return "LIVE";
  }
};
export const checkAvideo = (val) => {
  if (val == "vsl" || val == "AConversion") {
    return true;
  } else return false;
};

export const titleCaseType = (inputType) => {
  // Convert the inputType to lowercase for easier comparison

  inputType = inputType ? inputType?.toLowerCase() : inputType;

  // Perform a switch case based on the lowercase inputType
  switch (inputType) {
    case "AVideo":
      return "AConversion";
    case "aevent":
      return "AStream";
    case "activecampaign":
      return "ActiveCampaign";
    case "amazonses":
      return "Amazon SES";
    case "aweber":
      return "AWeber";
    case "calendar":
      return "Calendar";
    case "campaignmonitor":
      return "Campaign Monitor";
    case "campaignrefinery":
      return "Campaign Refinery";
    case "clickbank":
      return "ClickBank";
    case "constantcontact":
      return "Constant Contact";
    case "convertkit":
      return "ConvertKit";
    case "customdomain":
      return "Custom Domain";
    case "drip":
      return "Drip";
    case "dropcowboy":
      return "Drop Cowboy";
    case "fbcustomaudience":
      return "FB Custom Audience";
    case "getresponse":
      return "GetResponse";
    case "gohighlevel":
      return "GoHighLevel";
    case "googlecalendar":
      return "Google Calendar";
    case "googlesheets":
      return "Google Sheets";
    case "hubspot":
      return "HubSpot";
    case "icontact":
      return "IContact";
    case "infusionsoft":
      return "Keap";
    case "iterable":
      return "Iterable";
    case "kartra":
      return "Kartra";
    case "klaviyo":
      return "Klaviyo";
    case "mailchimp":
      return "Mailchimp";
    case "mailerlite":
      return "MailerLite";
    case "mailgun":
      return "MailGun";
    case "mailjet":
      return "MailJet";
    case "manychat":
      return "ManyChat";
    case "manychatwhatsapp":
      return "ManyChat Whatsapp";
    case "maropost":
      return "Maropost";
    case "niftyimages":
      return "NiftyImages";
    case "onesignal":
      return "OneSignal";
    case "ontraport":
      return "Ontraport";
    case "postmark":
      return "PostMark";
    case "pushcrew":
      return "PushCrew";
    case "roezan":
      return "Roezan";
    case "salesmessage":
      return "Sales Message";
    case "sendgrid":
      return "SendGrid";
    case "sendinblue":
      return "Brevo";
    case "sendlane":
      return "Sendlane";
    case "slack":
      return "Slack";
    case "slicktext":
      return "SlickText";
    case "slybroadcast":
      return "Slybroadcast";
    case "smartengage":
      return "SmartEngage";
    case "smtp":
      return "SMTP";
    case "sparkpost":
      return "SparkPost";
    case "twilio":
      return "Twilio";
    case "avideo":
      return "AVideo";
    case "wati":
      return "Wati";
    case "webhook":
      return "Webhook";
    case "gotowebinar":
      return "GoToWebinar";
    case "youtube":
      return "YouTube";
    case "zapier":
      return "Zapier";
    case "pabbly":
      return "Pabbly";
    case "zoom":
      return "Zoom";
    case "zoomautochat":
      return "Zoom Autochat";
    case "obvio":
      return "Obvio";
    case "niftyimage":
      return "NiftyImage";
    case "vsl":
      return "AConversion";
    case "whatsapp":
      return "Whatsapp";
    case "esp":
      return "ESP";
    case "sms":
      return "SMS";
    case "rvm":
      return "RVM";
    case "astream":
      return "AStream";
    case "calendly":
      return "Calendly";
    case "metacapi":
      return "FB Conversion";
    case "simpletexting":
      return "Simple Texting";
    case "superchat":
      return "Super Chat";
    case "tiktokbusinessapi":
      return "Tiktok for Business";
    default:
      return inputType;
  }
};

export const getNotifyImagePersonalizeData = () => {
  return {
    "{{!webinar-timeStamp!}}": {
      title: "Webinar Time",
      value: "{{!webinar-timeStamp!}}",
    },
    "{{!replay-timeStamp!}}": {
      title: "Replay Time",
      value: "{{!replay-timeStamp!}}",
    },
  };
};

export const getFirstElementOfDropdown = (obj) => {
  let keys = Object.keys(obj ?? {});
  if (keys.length > 0) {
    let firstValue = obj[keys[0]].value;
    return firstValue;
  } else return null;
};
export const stringifyNumber = (n) => {
  var special = [
    "zeroth",
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
    "seventh",
    "eighth",
    "ninth",
    "tenth",
    "eleventh",
    "twelfth",
    "thirteenth",
    "fourteenth",
    "fifteenth",
    "sixteenth",
    "seventeenth",
    "eighteenth",
    "nineteenth",
  ];
  var deca = [
    "twent",
    "thirt",
    "fort",
    "fift",
    "sixt",
    "sevent",
    "eight",
    "ninet",
  ];
  if (n < 20) return special[n];
  if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + "ieth";
  return deca[Math.floor(n / 10) - 2] + "y-" + special[n % 10];
};

export const adjustTimeForTimezone = (date, timezone, amPM = false) => {
  const inputTime = dayjs(date);
  const convertedTime = inputTime.tz(timezone);
  let formattedTime;
  if (amPM) formattedTime = convertedTime.format("hh:mm A");
  else formattedTime = convertedTime.format("HH:mm");
  return formattedTime;
};

export const handleActionDescription = (text) => {
  switch (text) {
    case "Voicemail":
      return "Audio";
    case "activecampaign":
      return "ActiveCampaign";
    case "Audience":
      return "Message";

    default:
      return text;
  }
};

export const deepEqual = (obj1, obj2) => {
  if (obj1 === obj2) return true;

  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  )
    return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

export const getStyleString = (pStyle) => {
  var sRet = "";
  Object.keys(pStyle).map((key) => {
    let sStr = pStyle[key];
    if (key == "color" || key == "background-color")
      sStr =
        "rgba(" +
        pStyle[key].rgba.r +
        "," +
        pStyle[key].rgba.g +
        "," +
        pStyle[key].rgba.b +
        "," +
        pStyle[key].rgba.a +
        ")";

    if (key == "font-size" || key == "letter-spacing") sStr += "px";
    sRet += key + ":" + sStr + ";";
  });
  return sRet;
};

export const generateNodeID = (node) => {
  let hrs, mins, sec;
  if (node.hours == null || node.hours == "") {
    hrs = 0;
  } else {
    hrs = parseInt(node.hours);
  }
  if (node.minutes == null || node.minutes == "") {
    mins = 0;
  } else {
    mins = parseInt(node.minutes);
  }
  if (node.seconds == null || node.seconds == "") {
    sec = 0;
  } else {
    sec = parseInt(node.seconds);
  }
  if (node.isExact) {
    if (hrs == 12) {
      if (node.amPm == "1") {
        return 60 * 12 + mins + sec / 60;
      }
      if (node.amPm == "0") {
        return 0 + mins + sec / 60;
      }
    }
    if (node.amPm == "1") {
      return 60 * (hrs + 12) + mins + sec / 60;
    }
    if (node.amPm == "0") {
      return 60 * hrs + mins + sec / 60;
    }
  } else {
    if (node.isAfter == "1") {
      return 60 * hrs + mins + sec / 60;
    } else {
      return 60 * 24 - (60 * hrs + mins + sec / 60);
    }
  }
};

export const getAllEvents = (general, videos = []) => {
  let countdownLength = getCountdownLength(general);
  let webinarLength = getWebinarLength(general, videos);
  return [
    {
      ...general,
      node: { days: 0, minutes: 0, hours: 0, isAfter: 1, id: "general" },
      countdown: {
        ...general.countdown,
        start: { day: 0, nodeID: 0 },
        end: { day: 0, nodeID: countdownLength },
      },
      live: {
        start: { day: 0, nodeID: 0 },
        end: { day: 0, nodeID: webinarLength },
      },
    },
    ...getInnerEvents(general, videos),
  ];
};

export const getInnerEvents = (general, videos = []) => {
  let ret = [];
  if (!general.multiEvents) return [];
  const nodesGroup = general.multiEvents.nodes;
  let days = Object.keys(nodesGroup);
  days = days.sort((a, b) => {
    return parseInt(a) < parseInt(b) ? -1 : 1;
  });
  days.map((dayItem) => {
    let nodes = nodesGroup[dayItem];
    let eventDay = parseInt(dayItem);
    nodes.map((node) => {
      let eventSetting = general.multiEvents.settings[node?.id];
      let countdownLength = getCountdownLength(eventSetting);
      let eventLength = getWebinarLength(eventSetting, videos);
      let liveParam = getDayAndNodeID(eventDay, node?.nodeID, eventLength);
      let countdownParam = getDayAndNodeID(
        eventDay,
        node?.nodeID,
        -countdownLength
      );
      const liveNodeID = generateNodeID(node);
      let event = {
        node: node,
        setting: eventSetting,
        name: node?.model?.eventName,
        live: {
          start: { day: eventDay, nodeID: liveNodeID },
          end: { day: liveParam.day, nodeID: liveNodeID + eventLength },
        },
        countdown: {
          enabled: countdownLength > 0,
          start: {
            day: countdownParam.day,
            nodeID: countdownParam.nodeID,
          },
          end: { day: eventDay, nodeID: node?.nodeID },
          duration: getCountdownLength(eventSetting),
        },
      };
      ret.push(event);
    });
  });
  return ret;
};

export const actionDuringWebinar = (
  integrationActionModel,
  generalSetting,
  videos = [],
  userTimeZone = null
) => {
  const fromEventID = integrationActionModel.model.fromEventID;
  if (Array.isArray(fromEventID) && fromEventID.length > 1) {
    return false;
  }
  let days =
    parseInt(integrationActionModel.days) *
    parseInt(integrationActionModel.isAfter);
  const allEvents = getAllEvents(generalSetting, videos);

  const webinarLength = getWebinarLength(generalSetting, videos);
  if (allEvents.length == 0) {
    if (parseInt(integrationActionModel.isAfter) == -1) {
      return false;
    }
    if (
      parseInt(days) > 0 ||
      (parseInt(days) == 0 &&
        generateNodeID(integrationActionModel) > webinarLength)
    ) {
      return false;
    }
  } else {
    let offset = 0;
    let totalMinutes = 0;
    const timeStamp = generalSetting?.timeStamp ?? 0; // Default to 0 if timeStamp is null or undefined
    const timeZone = generalSetting?.timeZone ?? userTimeZone;

    // Check for live-webinar and calculate offset
    if (generalSetting?.eventType === "live-webinar" && timeStamp) {
      const event = dayjs.tz(timeStamp, timeZone);
      totalMinutes = event.hour() * 60 + event.minute();
      offset = event.diff(event.startOf("day"), "minute");
    }

    let isValid = false;
    let dayKey = parseInt(days);
    allEvents.push({
      node: { isAfter: 1 },
      countdown: {
        start: { day: 0, nodeID: offset },
        end: { day: 0, nodeID: offset },
      },
      live: {
        start: { day: 0, nodeID: offset },
        end: { day: 0, nodeID: parseInt(offset) + webinarLength },
      },
    });

    allEvents.map((event, index) => {
      let currentNodeID = generateNodeID(integrationActionModel);
      const sameDirection =
        parseInt(event.node.isAfter) ==
        parseInt(integrationActionModel.isAfter);
      const countdownSameDay = parseInt(event.countdown.start.day) == dayKey;
      const liveSameDay = parseInt(event.live.start.day) == dayKey;
      let afterStart = false;

      if (integrationActionModel.isExact) {
        console.log(
          event.live.end.nodeID + offset,
          currentNodeID,
          "exactafterStart"
        );

        afterStart = event.live.start.nodeID < currentNodeID;
      } else {
        if (generalSetting?.eventType === "live-webinar" && timeStamp) {
          const event_time = dayjs.tz(timeStamp, timeZone);
          totalMinutes = event_time.hour() * 60 + event_time.minute();
          offset = event_time.diff(event_time.startOf("day"), "minute");
        }
        afterStart =
          event.live.start.nodeID <
          currentNodeID + (isNaN(offset) ? 0 : offset);
      }

      let beforeEnd = false;
      if (dayKey == 0) {
        let minutes = 0;

        if (timeStamp) {
          const webinarTime = timeStamp.split(" ")[1];
          if (webinarTime) {
            const timeParts = webinarTime.split(":");
            if (timeParts.length === 2) {
              minutes =
                parseInt(timeParts[0], 10) * 60 + parseInt(timeParts[1], 10);
            }
          }
        }
        if (integrationActionModel.isExact) {
          beforeEnd =
            event.live.end.nodeID - event.live.start.nodeID > currentNodeID - minutes ||
            parseInt(event.live.end.day) > parseInt(dayKey);
        } else {
          beforeEnd =
            event.live.end.nodeID - event.live.start.nodeID > currentNodeID ||
            parseInt(event.live.end.day) > parseInt(dayKey);
        }
      } else {
        if (integrationActionModel.isExact) {
          console.log(event.live.end.nodeID + offset, currentNodeID, "exact");

          beforeEnd =
            event.live.end.nodeID > currentNodeID ||
            parseInt(event.live.end.day) > parseInt(dayKey);
        } else {
          if (generalSetting?.eventType === "live-webinar" && timeStamp) {
            const event_time = dayjs.tz(timeStamp, timeZone);
            totalMinutes = event_time.hour() * 60 + event_time.minute();
            offset = event_time.diff(event_time.startOf("day"), "minute");
          }
          beforeEnd =
            event.live.end.nodeID > currentNodeID + offset ||
            parseInt(event.live.end.day) > parseInt(dayKey);
        }
      }

      if (
        !sameDirection &&
        (generalSetting?.eventType !== "live-webinar" || dayKey != 0)
      )
        return;
      if (!countdownSameDay && !liveSameDay) return;
      if (afterStart && beforeEnd) {
        isValid = true;
        return;
      }
    });
    return isValid;
  }
  return true;
};

export const attendanceDataAvailable = (
  integrationActionModel,
  generalSetting,
  videos = []
) => {
  return (
    integrationActionModel.isAfter > 0 &&
    (generalSetting?.deliveryPlatform == "aevent" ||
      !actionDuringWebinar(integrationActionModel, generalSetting, videos))
  );
};

/**
 * Some actions cannot run after webinar computer loads event (clonejob);
 * Allow 15 min or countdown + 10min before start.
 */
export const actionAfterServerOpensWebinar = (
  integrationActionModel,
  generalSetting,
  videos = []
) => {
  const fromEventID = integrationActionModel.model.fromEventID ?? "general";
  if (Array.isArray(fromEventID) && fromEventID.length > 1) {
    return false;
  }
  let eventID = Array.isArray(fromEventID) ? fromEventID?.[0] : fromEventID;
  if (eventID === "main") {
    eventID = "general";
  }
  eventID ??= "general";
  let days = parseInt(getInsertDayKeyFromNode(integrationActionModel));
  // Event occurs "midnight of day 0" for most purposes, but change subject can be before main event on the same day.
  if (
    days == -1 &&
    integrationActionModel.days == 0 &&
    ["general", "main"].includes(eventID)
  ) {
    days = 0;
  }
  let currentNodeID = generateNodeID(integrationActionModel);
  let insertDayKey = integrationActionModel.insertDayKey;
  let dir = parseInt(integrationActionModel.isAfter);
  const allEvents = getAllEvents(generalSetting, videos);
  const webinarLength = getWebinarLength(generalSetting, videos);
  const relativeToMainNodeID =
    dir * ((parseInt(days) - (dir - 1) / 2) * 24 * 60 + dir * currentNodeID);
  const event = allEvents.find((event) => event.node.id === eventID);
  if (!event) return true;
  const nodeID = ["general", "main"].includes(event.node.id)
    ? relativeToMainNodeID
    : currentNodeID;
  let nodeDays = getInsertDayKeyFromNode(event.node);
  const sameDay = parseInt(event.node.days) == days;
  const laterDay = dir * parseInt(event.node.days) < dir * days;
  let afterCountdownLoaded = false;
  if (event.countdown.enabled && laterDay) {
    afterCountdownLoaded = true;
  } else if (event.countdown.enabled && sameDay) {
    // Server usually opens webinar (breaking change subject, etc.) 11 minutes before countdown.
    const countdownStarts = event.live.start.nodeID - event.countdown.duration;
    const timeUntilCountdown = countdownStarts - nodeID;
    const countdownBuffer = 11;
    afterCountdownLoaded = timeUntilCountdown < countdownBuffer;
  }
  // Without countdown, server usually opens 11 minutes before start.
  const afterPageLoaded =
    !event.countdown.enabled &&
    (laterDay || (sameDay && nodeID >= event.live.start.nodeID - 11));
  return afterCountdownLoaded || afterPageLoaded;
};

export const checkChangeSubject = (
  integrationActionModel,
  generalSetting,
  videos = []
) => {
  if (generalSetting.deliveryPlatform === "aevent") return false;
  if (actionDuringWebinar(integrationActionModel, generalSetting, videos))
    return false;
  const fromEventID = integrationActionModel.model.fromEventID ?? "general";
  if (
    actionAfterServerOpensWebinar(
      integrationActionModel,
      generalSetting,
      videos
    )
  )
    return false;
  return true;
};

export const getVideoLengthByID = (fileID, videos = []) => {
  let videoFile = videos.find((it) => it.uniqueID == fileID);
  if (!videoFile) return 0;
  let timeArray = videoFile.video_length.split(":");
  return (
    parseInt(timeArray[0] || 0) * 60 * 60 +
    parseInt(timeArray[1] || 0) * 60 +
    parseInt(timeArray[2] || 0)
  );
};

export const convertTime12To24 = (
  time = "",
  padBoth = false,
  reverse = false
) => {
  if (!reverse) {
    let hours = parseInt(time?.split(":")?.[0] ?? "0") ?? 0;
    let minutes = parseInt(time?.split(":")?.[1]?.split(" ")?.[0] ?? "0") ?? 0;
    let amPm = time?.split(":")?.[1]?.split(" ")?.[1];
    if (amPm == "PM") hours += 12;
    if (padBoth)
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
    else return `${hours}:${minutes.toString().padStart(2, "0")}`;
  } else {
    let hours = parseInt(time.split(":")[0]);
    let minutes = parseInt(time.split(":")[1]);
    let amPm = "AM";
    if (hours > 12) {
      hours -= 12;
      amPm = "PM";
    }
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${amPm}`;
  }
};

export const timeStampConversion = (
  date = null,
  time = null,
  padZeros = false
) => {
  let result;
  const newDate = date ? new Date(date) : new Date();
  const month = newDate.getMonth() + 1;
  const day = newDate.getDate();
  const year = newDate.getFullYear();
  if (time) {
    result = `${year}/${month.toString().padStart(padZeros ? 2 : 0, "0")}/${day
      .toString()
      .padStart(padZeros ? 2 : 0, "0")}T${convertTime12To24(
      time,
      true
    )}:00.000Z`;
  } else
    result = `${year}/${month.toString().padStart(padZeros ? 2 : 0, "0")}/${day
      .toString()
      .padStart(padZeros ? 2 : 0, "0")}T00:00:00.000Z`;
  return result;
};

export const getColorStyle = (pColor) => {
  if (!pColor) return;
  const [r, g, b, a] = [
    pColor?.rgba?.r ?? "",
    pColor?.rgba?.g ?? "",
    pColor?.rgba?.b ?? "",
    pColor?.rgba?.a ?? "",
  ];
  return `rgba(${r},${g},${b},${a})`;
};

export const formatMaroPostData = (obj) => {
  let formarttedIntegrationData = {};
  Object.values(obj).map((item, _idx) => {
    if (item) {
      const key =
        item.id != undefined
          ? item.id
          : item.tagId != undefined
          ? item.tagId
          : item.list_id;
      formarttedIntegrationData[item.name] = {
        title: item.name,
        value: item.name,
      };
    }
  });
  return formarttedIntegrationData;
};
export const formatKartraData = (obj) => {
  let formarttedIntegrationData = {};
  Object.values(obj).map((item, _idx) => {
    if (item) {
      const key =
        item.id != undefined
          ? item.id
          : item.tagId != undefined
          ? item.tagId
          : item.list_id;
      formarttedIntegrationData[item.name] = {
        title: item.name,
        value: item.name,
      };
    }
  });
  return formarttedIntegrationData;
};
export const formatWatiData = (obj) => {
  let formarttedIntegrationData = {};
  Object.values(obj).map((item, _idx) => {
    if (item) {
      const key =
        item.id != undefined
          ? item.id
          : item.tagId != undefined
          ? item.tagId
          : item.list_id;
      formarttedIntegrationData[item.name] = {
        title: item.name,
        value: item.name,
      };
    }
  });
  return formarttedIntegrationData;
};
export const getMailChimpWorkflowData = (workflows) => {
  let ret = {};
  Object.keys(workflows).map((id) => {
    ret[id] = {
      title: workflows[id].trigger_settings.workflow_title,
      value: id,
    };
  });
  return ret;
};
export const getMailChimpTagData = (tags) => {
  return Object.fromEntries(
    Object.entries(tags ?? {}).map(([id, tag]) => [
      tag.name,
      { title: tag.name, value: tag.name },
    ])
  );
};
export const formatZapierData = (obj) => {
  let formarttedIntegrationData = {};
  Object.values(obj).map((item, _idx) => {
    if (item) {
      formarttedIntegrationData[item.label] = {
        title: item.label,
        value: item.label,
      };
    }
  });
  return formarttedIntegrationData;
};
export const formatPabblyData = (obj) => {
  let formarttedIntegrationData = {};
  Object.values(obj).map((item, _idx) => {
    if (item) {
      formarttedIntegrationData[item.label] = {
        title: item.label,
        value: item.label,
      };
    }
  });
  return formarttedIntegrationData;
};
export const formatOntraPortData = (obj) => {
  let formarttedIntegrationData = {};
  Object.values(obj).map((item, _idx) => {
    if (item) {
      const key =
        item.id != undefined
          ? item.id
          : item.tagId != undefined
          ? item.tagId
          : item.list_id;
      formarttedIntegrationData[item.name] = {
        title: item.name,
        value: item.name,
      };
    }
  });
  return formarttedIntegrationData;
};

const guessTimeFormat = (fieldData, integrationType = "", field = {}) => {
  if (fieldData.match(/timestamp/i)) return "default";
  if (fieldData.match(/year/i)) return "Y";
  if (fieldData.match(/month/i)) return "F";
  if (fieldData.match(/week|dow/i)) return "l";
  if (fieldData.match(/dom/i)) return "dS";
  if (
    integrationType === "HUBSPOT" &&
    (field?.type === "date" || fieldData.match(/day/i))
  ) {
    return "midnight";
  }
  if (fieldData.match(/day/i)) {
    return "dS";
  }
  return "UserTimezone";
};

export const autoselect = (fieldID, fields, integrationType = "") => {
  const lookup = Object.fromEntries(
    Object.entries(fields ?? {}).map(([id, field]) => [
      id,
      Object.values(field ?? {}).reduce((acc, elem) => {
        if (typeof elem !== "string") return acc;
        return acc + elem;
      }),
    ])
  );
  const fieldData = lookup[fieldID] ?? fieldID ?? "";
  const field = fields?.[fieldID];

  if (fieldData.match(/uuid|registrantID/i)) {
    return { format: "uuid", value: "url" };
  }
  if (fieldData.match(/replay.*(link|url)/i)) {
    return { format: "replay-url", value: "url" };
  }
  if (fieldData.match(/join|url|link/i)) {
    return { format: "join-url", value: "url" };
  }
  if (fieldData.match(/phone/i)) {
    return { format: "phone", value: "url" };
  }
  if (fieldData.match(/email/i)) {
    return { format: "email", value: "url" };
  }
  if (fieldData.match(/webinar.*(month|year|day|time|week|dow|dom)/i)) {
    return {
      format: guessTimeFormat(fieldData, integrationType, field),
      value: "webinar-time-reg",
    };
  }
  if (fieldData.match(/replay.*(month|year|day|time|week|dow|dom)/i)) {
    return {
      format: guessTimeFormat(fieldData, integrationType, field),
      value: "replay-time-reg",
    };
  }
  if (fieldData.match(/registration.*(month|year|day|time|week|dow|dom)/i)) {
    return {
      format: guessTimeFormat(fieldData, integrationType, field),
      value: "registration-time-reg",
    };
  }
  if (fieldData.match(/platform/i)) {
    return { format: "platformURL", value: "url" };
  }
  if (fieldData.match(/first.*name/i)) {
    return { format: "firstName", value: "url" };
  }
  if (fieldData.match(/last.*name/i)) {
    return { format: "lastName", value: "url" };
  }
  if (fieldData.match(/.*name/i)) {
    return { format: "firstName", value: "url" };
  }
  if (fieldData.match(/webinarID/i)) {
    return { format: "webinarID", value: "url" };
  }
  if (fieldData.match(/country/i)) {
    return { format: "country", value: "url" };
  }
  return {
    format: guessTimeFormat(fieldData, integrationType, field),
    value: "webinar-time-reg",
  };
};

// Finds system fields ids for most integrations.
// Override with a custom callback to settingsStore.selectSystemFields
export const getSystemFields = (fields, customFieldTemplates) => {
  const systemFieldNames = Object.keys(customFieldTemplates ?? {});
  if (systemFieldNames.length < 1) return;
  const systemFieldRegex = new RegExp(systemFieldNames.join("|"), "i");
  return Object.entries(fields ?? {})
    .filter(([id, field]) => {
      if (typeof field === "object") {
        const fieldName = field.title ?? field.name ?? field.value ?? "";
        return fieldName.match?.(systemFieldRegex);
      }
      if (typeof field === "string" && field.match(/[^0-9]/)) {
        return field.match(systemFieldRegex);
      }
      if (typeof id === "string" && id.match(/[^0-9]/)) {
        return id.match(systemFieldRegex);
      }
      return false;
    });
};
const getFieldID = (key, field) => {
  if (typeof field === "string") return field;
  if (typeof field !== "object") return "";
  return field.value ?? field.name ?? field.title ?? "";
};
// Autoselects a system field for most integrations.
// Override with a custom callback to settingsStore.selectSystemFields
export const selectField = ({ props, setid }, getter = null) => {
  return ([key, value]) => {
    const get = getter ?? getFieldID;
    const id = get(key, value);
    if (!id) return;
    setid.value.push(id);
    props.autoresponder.integrations[
      props.settingsData.integrationid
    ].customFields[id] = autoselect(id, props.settingsData.customFields);
  };
};

export const getActionsDescription = (IntegrationsList, type, action) => {
  let action_title = action?.split("-")[1] || action;

  if (IntegrationsList[type] && IntegrationsList[type] != undefined) {
    if (
      IntegrationsList[type][action_title] &&
      IntegrationsList[type][action_title] != undefined
    ) {
      return IntegrationsList[type][action_title];
    } else {
      return {};
    }
  } else {
    return {};
  }
};
export const gtwSubjectValidation = (subject) => {
  return /[#&\[\]<>\\|/]/.test(subject);
};

export const decompress = (string) => {
  const binaryString = atob(string);
  const charCodes = binaryString.split("").map((char) => char.charCodeAt(0));
  const compressedData = new Uint8Array(charCodes);
  const decompressedData = pako.ungzip(compressedData);
  return new TextDecoder().decode(decompressedData);
};

export const isHTMLValid = (html) => {
  if (typeof html !== "string") return false;
  let doc = new DOMParser().parseFromString(html, "text/html");
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
};

export const isFieldNullable = (field) => {
  if (field?.value !== "url" && field?.value !== "custom-field") return false;
  const requiredFields = [
    "uuid",
    "email",
    "firstName",
    "join-url",
    "webinarID",
    "apple_calendar",
    "google_calendar",
    "attendance",
  ];
  return !requiredFields.includes(field?.format);
};

export const createTimelineData = () => {
  return {
    integration: [],
    general: {
      timelineName: "",
      description: "",
      deliveryPlatform: "",
      webinarType: "",
      eventType: "",
      organizer: [],
      timeStamp: null,
      timeZone: "America/Los_Angeles",
      visible: true,
      templateType: "",
      language: "",
      highDefinition: {
        enabled: false,
      },
      userGroup: [
        {
          name: "Registrants",
          id: "3",
          conditions: [],
        },
        {
          name: "Attendee",
          id: "a1",
          conditions: [
            {
              if: "attended-hour",
              condition: ">",
              duration: "0",
            },
          ],
        },
        {
          name: "Non-Attendee",
          id: "a2",
          conditions: [
            {
              if: "attended-hour",
              condition: "=",
              duration: "0",
            },
          ],
        },
      ],
      presenters: [],
    },
    autoresponder: {
      integrations: [],
      webinarEnabled: "false",
      confirmationEnabled: "false",
      reminderEnabled: "false",
      nonattendeeEnabled: "false",
      attendeeEnabled: "false",
      multiEvents: {},
      confirmation_title: "",
      confirmation_body: "",
      nonattendee_title: "",
      nonattendee_body: "",
      reminder_title: "",
      reminder_body: "",
      attendee_title: "",
      attendee_body: "",
    },
    registration: {
      customForms: [],
      isInnerPageEnabled: false,
      type: "multi-option",
      setupType: "page",
      registrationBody: "",
      registrationSubject: "",
    },
  };
  // if (expert) showCreateTLModal.value = true;
};

export function convertMinutes(seconds) {
  const hours = Math.floor(seconds / 3600);
  const remainingSecondsAfterHours = seconds % 3600;
  const minutes = Math.floor(remainingSecondsAfterHours / 60);
  const remainingSeconds = Math.round(remainingSecondsAfterHours % 60);

  let result = "";
  if (hours > 0) {
    result += `${hours}h `;
  }
  if (minutes > 0 || hours > 0) {
    result += `${minutes}m `;
  }
  if (remainingSeconds > 0 || (hours === 0 && minutes === 0)) {
    result += `${remainingSeconds}s`;
  }
  return result.trim();
}
