<template>
  <div
    class="a8-banner font-manrope !bg-blue-600 relative flex items-center gap-3"
    :style="{ backgroundColor }"
    v-if="shouldShow"
  >
    <div class="mx-auto md:max-w-[680px] w-full py-2 px-3 sm:px-6 lg:px-8">
      <div class="flex flex-wrap items-center justify-between">
        <div class="flex items-center gap-3">
          <span class="flex rounded-lg" :style="bgStyle(1)">
            <img src="/icons/banner_icon.svg" alt="banner icon" class="w-5 h-5"/>
          </span>
          <p class="truncate font-medium text-white text-sm">
            <span class="md:hidden">{{ mobileHeading }}</span>
            <span class="hidden md:inline">{{ fullHeading }}</span>
          </p>
        </div>
        <a
          :href="link"
          class="font-bold text-sm underline underline-offset-1 !text-white"
          :style="textStyle"
          target="_blank"
          >{{ buttonTitle }}</a
        >
      </div>
    </div>
    <div class="md:absolute md:top-1.5 right-4 flex justify-center">
      <button type="button" @click="closeBanner">
        <span class="sr-only">Dismiss</span>
        <!-- Heroicon name: outline/x-mark -->
        <svg
          class="h-6 w-6 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script setup>
const backgroundColor = ref("#2B67AA");
const mobileHeading = ref("");
const fullHeading = ref("");
const buttonTitle = ref("");
const link = ref("");
const bannerOpen = ref(true);
const router = useRouter();
const emit = defineEmits(["closedBanner"]);

const bgStyle = (brightness) => {
  return `background-color: ${backgroundColor}; filter: brightness(${brightness});`;
};

const getBanner = async () => {
  const res = await call("/localization/banner", "GET");
  backgroundColor.value = res.content?.color ?? "";
  mobileHeading.value = res.content?.mobileHeading ?? "";
  fullHeading.value = res.content?.fullHeading ?? "";
  buttonTitle.value = res.content?.buttonTitle ?? "";
  link.value = res.content.link;
  if (!res.content?.fullHeading) {
    bannerOpen.value = false;
  }
  nextTick(() => {
    if (alreadyClosed()) {
      bannerOpen.value = false;
    }
  });
};

const closeBanner = () => {
  bannerOpen.value = false;
  emit("closedBanner");
  localStorage.setItem("bannerClose", fullHeading.value);
};

const textStyle = computed(() => {
  return `color: ${backgroundColor.value};`;
});

const shouldShow = computed(() => {
  const notOnTimelineEditor =
    router.currentRoute?.value?.name !== "timelines-id";
  const notInIntegrationCallback =
    typeof window != "undefined" &&
    window &&
    !window?.location.href.match(/callback/);
  const hasData = fullHeading.value !== "";
  return (
    bannerOpen.value &&
    hasData &&
    notOnTimelineEditor &&
    notInIntegrationCallback
  );
});

const alreadyClosed = () => {
  return localStorage.getItem("bannerClose") === fullHeading.value;
};

onMounted(getBanner);
</script>